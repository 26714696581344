import { Tab, TabList, mergeClasses } from '@fluentui/react-components';
import { Add20Regular, Search20Regular } from '@fluentui/react-icons';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useMatch, useNavigate, useParams } from 'react-router-dom';
import { SignInContext } from '../app';
import { IntlProps, TrackContext, TrackedButton } from '../components/common';
import NotSignedInState from '../components/common/not-signed-in-state';
import CourseProgressList from '../components/course-progress-list/course-progress-list';
import PageContainer from '../components/page-container';
import CreateProjectDialog from '../components/project-list/create-project-dialog';
import ProjectList from '../components/project-list/project-list';
import PublishedProjectList from '../components/published-project-list/published-project-list';
import ShareProjectDialog from '../components/published-project-list/share-project-dialog';
import {
    UserContext,
    getIsLoadingProgress,
    getIsLoadingProjects,
    getIsSignedIn,
    getPublishedProjects,
    getSortedProjects,
    getSortedUserProgress,
    useProjects,
    useUserProgress,
} from '../data';
import { useAppStyles } from '../styles';

// My work page tab names.
export type TabName = 'courses' | 'projects' | 'published';

// My work page.
export default injectIntl((props: IntlProps) => {
    const { intl } = props;
    const styles = useAppStyles();
    const params = useParams();
    const [activeTab, setActiveTab] = useState(params['tabName'] ?? 'courses');
    const navigate = useNavigate();
    const newProjectRoute = useMatch('/my-work/projects/new');

    const [userStore] = useContext(UserContext);
    const isSignedIn = getIsSignedIn(userStore);

    const [projectStore, projectDispatch] = useProjects();
    const projectsLoading = getIsLoadingProjects(projectStore);
    const projects = getSortedProjects(projectStore);
    const publishedProjects = getPublishedProjects(projectStore);
    const [createDialogOpen, setCreateDialogOpen] = React.useState(false);
    const [shareDialogOpen, setShareDialogOpen] = React.useState(false);
    const [shareDialogTarget, setShareDialogTarget] = React.useState<string>();

    const [progressStore, progressDispatch] = useUserProgress();
    const progressLoading = getIsLoadingProgress(progressStore);
    const progress = getSortedUserProgress(progressStore);

    // Track tab changes in the address bar.
    const selectTab = useCallback(
        (tabName: TabName, replace?: boolean) => {
            setActiveTab(tabName);
            navigate(`/my-work/${tabName}`, { replace });
        },
        [setActiveTab, navigate]
    );

    // Show Published projects when there are any or a project is being published.
    const showPublished = useMemo(() => {
        return publishedProjects.length > 0 || projects.some((p) => p.state === 'Publishing');
    }, [publishedProjects, projects]);

    // Switch to My projects tab once Published projects tab disappears.
    useEffect(() => {
        if (!showPublished && !projectsLoading && activeTab === 'published') {
            selectTab('projects');
        }
    }, [showPublished, activeTab, selectTab, projectsLoading]);

    // Handle my-work/projects/new route: sign-in, then show Create Project dialog.
    const { ensureSignedIn } = useContext(SignInContext);
    useEffect(() => {
        if (newProjectRoute) {
            if (!isSignedIn) {
                ensureSignedIn('/my-work/projects/new');
            } else if (!projectsLoading) {
                selectTab('projects', true);
                if (projects.length === 0) {
                    setCreateDialogOpen(true);
                }
            }
        }
    }, [newProjectRoute, isSignedIn, projectsLoading, projects, navigate, selectTab, ensureSignedIn]);

    return (
        <TrackContext.Provider value={{ container: 'my-work' }}>
            <PageContainer className={styles.pageContainer}>
                <div className={mergeClasses(styles.pageContent, 'content-width')}>
                    {!isSignedIn && <NotSignedInState />}
                    {isSignedIn && (
                        <div className={mergeClasses(styles.section, styles.flexGrow1)}>
                            <div className={styles.flexRowCentered}>
                                <TabList
                                    selectedValue={activeTab}
                                    onTabSelect={(_, { value }) => selectTab(value as TabName)}
                                    style={{ marginLeft: '0.1rem' }}
                                >
                                    <Tab value="courses">
                                        <FormattedMessage
                                            description="Tab button title the My Work page"
                                            defaultMessage="My courses"
                                        />
                                    </Tab>
                                    <Tab value="projects">
                                        <FormattedMessage
                                            description="Tab button title the My Work page"
                                            defaultMessage="My projects"
                                        />
                                    </Tab>
                                    {showPublished && (
                                        <Tab value="published">
                                            <FormattedMessage
                                                description="Tab button title the My Work page"
                                                defaultMessage="Published projects"
                                            />
                                        </Tab>
                                    )}
                                </TabList>
                                <div className={styles.flexGrow1} />
                                {activeTab === 'projects' && projects.length > 0 && (
                                    <>
                                        <TrackedButton
                                            trackAction="new-project"
                                            className={styles.mobileHidden}
                                            appearance="primary"
                                            size="large"
                                            onClick={() => setCreateDialogOpen(true)}
                                        >
                                            <FormattedMessage
                                                description="Create new project button title"
                                                defaultMessage="Create new project"
                                            />
                                        </TrackedButton>
                                        <TrackedButton
                                            trackAction="new-project"
                                            className={styles.mobileOnly}
                                            appearance="primary"
                                            size="medium"
                                            shape="circular"
                                            icon={<Add20Regular />}
                                            title={intl.formatMessage({
                                                description: 'Tooltip for create project button',
                                                defaultMessage: 'Create new project',
                                            })}
                                            onClick={() => setCreateDialogOpen(true)}
                                        />
                                    </>
                                )}
                                {activeTab === 'courses' && progress.length > 0 && (
                                    <>
                                        <TrackedButton
                                            trackAction="explore-more-activities"
                                            className={styles.mobileHidden}
                                            appearance="primary"
                                            size="large"
                                            onClick={() => navigate('/courses')}
                                        >
                                            <FormattedMessage
                                                description="Explore more courses button title"
                                                defaultMessage="Explore more activities"
                                            />
                                        </TrackedButton>
                                        <TrackedButton
                                            trackAction="explore-more-activities"
                                            className={styles.mobileOnly}
                                            appearance="primary"
                                            size="medium"
                                            shape="circular"
                                            icon={<Search20Regular />}
                                            title={intl.formatMessage({
                                                description: 'Tooltip for explore more activities',
                                                defaultMessage: 'Explore more activities',
                                            })}
                                            onClick={() => navigate('/courses')}
                                        />
                                    </>
                                )}
                            </div>
                            <div className={mergeClasses(styles.sectionContent, styles.flexGrow1)}>
                                {activeTab === 'courses' && (
                                    <CourseProgressList
                                        isLoading={progressLoading}
                                        items={progress}
                                        onDownload={(courseId) => progressDispatch({ type: 'Download', courseId })}
                                        onUnenroll={(courseId) => progressDispatch({ type: 'Unenroll', courseId })}
                                    />
                                )}
                                {activeTab === 'projects' && (
                                    <ProjectList
                                        isLoading={projectsLoading}
                                        projects={projects}
                                        onCreate={() => setCreateDialogOpen(true)}
                                        onPublish={(projectId) => {
                                            projectDispatch({ type: 'Publish', projectId });
                                            selectTab('published');
                                        }}
                                        onShowPublished={() => selectTab('published')}
                                        onUpdate={(project) => projectDispatch({ type: 'Update', project })}
                                        onDelete={(projectId) => projectDispatch({ type: 'Delete', projectId })}
                                        onDownload={(projectId) => projectDispatch({ type: 'Download', projectId })}
                                        onShare={(projectId) => {
                                            setShareDialogTarget(projectId);
                                            setShareDialogOpen(true);
                                        }}
                                    />
                                )}
                                {activeTab === 'published' && (
                                    <PublishedProjectList
                                        projects={publishedProjects}
                                        onUpdate={(project) => projectDispatch({ type: 'Update', project })}
                                        onPublish={(projectId) => projectDispatch({ type: 'Publish', projectId })}
                                        onUnpublish={(projectId) => projectDispatch({ type: 'Unpublish', projectId })}
                                        onShare={(projectId) => {
                                            setShareDialogTarget(projectId);
                                            setShareDialogOpen(true);
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                    )}
                </div>
                {createDialogOpen && (
                    <CreateProjectDialog defaultOpen onOpenChange={(_, { open }) => setCreateDialogOpen(open)} />
                )}
                {shareDialogOpen && (
                    <ShareProjectDialog
                        defaultOpen
                        onOpenChange={(_, { open }) => setShareDialogOpen(open)}
                        projectId={shareDialogTarget!}
                    />
                )}
            </PageContainer>
        </TrackContext.Provider>
    );
});
