import { mergeClasses } from '@fluentui/react-components';
import { Add20Regular } from '@fluentui/react-icons';
import React, { useContext } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { IntlProps, NotSignedInState, TrackContext, TrackedButton } from '../components/common';
import CreateActivityDialog from '../components/curriculum-list/create-activity-dialog';
import CurriculumList from '../components/curriculum-list/curriculum-list';
import PageContainer from '../components/page-container';
import { getIsSignedIn, UserContext } from '../data';
import { getIsLoadingCurricula, useCurricula } from '../data/curriculum-context';
import { useAppStyles } from '../styles';

// Authoring page.
export default injectIntl(({ intl }: IntlProps) => {
    const appStyles = useAppStyles();
    const [userStore] = useContext(UserContext);
    const isSignedIn = getIsSignedIn(userStore);
    const [store, dispatch] = useCurricula();
    const isLoading = getIsLoadingCurricula(store);
    const [createDialogOpen, setCreateDialogOpen] = React.useState(false);

    return (
        <TrackContext.Provider value={{ container: 'authoring' }}>
            <PageContainer className={appStyles.pageContainer}>
                <div className={mergeClasses(appStyles.pageContent, 'content-width')}>
                    {!isSignedIn && <NotSignedInState />}
                    {isSignedIn && (
                        <div className={mergeClasses(appStyles.section)}>
                            <div className={appStyles.flexRowCentered}>
                                <h1>
                                    <FormattedMessage
                                        description="Authoring page section title"
                                        defaultMessage="Authoring"
                                    />
                                </h1>
                                <div className={appStyles.flexGrow1} />
                                {store.items.length > 0 && (
                                    <>
                                        <TrackedButton
                                            trackAction="new-activity"
                                            className={mergeClasses(appStyles.mobileHidden, appStyles.buttonPrimary)}
                                            appearance="primary"
                                            size="large"
                                            onClick={() => setCreateDialogOpen(true)}
                                        >
                                            <FormattedMessage
                                                description="Create new activity button title"
                                                defaultMessage="Create new activity"
                                            />
                                        </TrackedButton>
                                        <TrackedButton
                                            trackAction="new-activity"
                                            className={appStyles.mobileOnly}
                                            appearance="primary"
                                            size="medium"
                                            shape="circular"
                                            icon={<Add20Regular />}
                                            onClick={() => setCreateDialogOpen(true)}
                                            title={intl.formatMessage({
                                                description: 'Tooltip for create activity button',
                                                defaultMessage: 'Create new activity',
                                            })}
                                        />
                                    </>
                                )}
                            </div>
                            <div className={mergeClasses(appStyles.sectionContent, appStyles.flexGrow1)}>
                                <CurriculumList
                                    isLoading={isLoading}
                                    curricula={store.items}
                                    onCreate={() => setCreateDialogOpen(true)}
                                    onPublish={(curriculumId) => dispatch({ type: 'Publish', curriculumId })}
                                    onUnpublish={(curriculumId) => dispatch({ type: 'Publish', curriculumId })}
                                    onUpdate={(curriculum) => dispatch({ type: 'Update', curriculum })}
                                    onDelete={(curriculumId) => dispatch({ type: 'Delete', curriculumId })}
                                    onDownload={(curriculumId) => dispatch({ type: 'Download', curriculumId })}
                                />
                            </div>
                        </div>
                    )}
                </div>
                {createDialogOpen && (
                    <CreateActivityDialog defaultOpen onOpenChange={(_, { open }) => setCreateDialogOpen(open)} />
                )}
            </PageContainer>
        </TrackContext.Provider>
    );
});
