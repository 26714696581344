import {
    AuthProvider,
    StartSessionOptions,
    ApiEnvironment,
    VSCodeUrl,
    assert,
    isAbsoluteUrl,
    isNonEmptyString,
} from '@microsoft/vscodeedu-api';
import { decodeSessionToken } from '@microsoft/vscodeedu-auth-util';
import { KnownAuthQueryParams } from '@microsoft/vscodeedu-common';
import { User } from '../models';
import { getRouteUrl, trackInfo, trackWarning } from '../utilities';
import { getSharedLinkId } from '../utilities/sharedLinks';
import { anonymousClient, apiEnvironment } from './config-context';

const prefix = 'VSCodeEduIdentityProvider:';
const rememberMeKey = `${prefix}rememberMe`;
const signInRouteKey = `${prefix}signInRoute`;
const tokenKey = `${prefix}token`;
const vscodeSignInKey = `${prefix}.vscodeSignIn`;

type VSCodeSignInParams = {
    requestId: string;
    redirectUrl: string;
    codeChallenge: string;
    rememberMe: boolean;
};

// Error thrown when redirect to another domain has been initiated.
export class Redirecting extends Error {}

// Authentication provider.
export type AuthType = 'Microsoft' | 'Google' | 'Clever' | 'GitHub';

// VS Code for Education native identity provider.
export class VSCodeEduIdentityProvider {
    private _token: string | undefined | null = null;

    private trace = (message: string) => trackInfo(`${prefix} ${message}`);
    private warning = (message: string) => trackWarning(`${prefix} ${message}`);

    // Returns whether rememberMe was set for this session.
    private get rememberMe(): boolean {
        return sessionStorage.getItem(rememberMeKey) === 'true';
    }

    // Updates rememberMe value for this session.
    private set rememberMe(value: boolean) {
        if (value) {
            this.trace('rememberMe: Setting rememberMe to true in session storage.');
            sessionStorage.setItem(rememberMeKey, 'true');
        } else {
            this.trace('rememberMe: Removing rememberMe from session storage.');
            sessionStorage.removeItem(rememberMeKey);
        }
    }

    // Returns sign-in route set for this session.
    private get signInRoute(): string | undefined {
        return sessionStorage.getItem(signInRouteKey) ?? undefined;
    }

    // Updates sign-in route for this session.
    private set signInRoute(value: string | undefined) {
        if (isNonEmptyString(value)) {
            this.trace('signInRoute: Storing signInRoute in session storage.');
            sessionStorage.setItem(signInRouteKey, value);
        } else {
            this.trace('signInRoute: Removing signInRoute from session storage.');
            sessionStorage.removeItem(signInRouteKey);
        }
    }

    // Returns current session token.
    private get token(): string | undefined {
        if (this._token === null) {
            this._token = sessionStorage.getItem(tokenKey) ?? localStorage.getItem(tokenKey) ?? undefined;
        }
        return this._token;
    }

    // Updates current session token.
    // Stores token in local or session storage based on rememberMe value.
    private set token(value: string | undefined) {
        this._token = value;
        if (isNonEmptyString(value)) {
            if (this.rememberMe) {
                this.trace('token: Storing session token in local storage.');
                localStorage.setItem(tokenKey, value);
            } else {
                this.trace('token: Storing session token in session storage.');
                sessionStorage.setItem(tokenKey, value);
            }
        } else {
            this.trace('token: Removing session token from storage.');
            localStorage.removeItem(tokenKey);
            sessionStorage.removeItem(tokenKey);
        }
    }

    // Returns vscode.dev sign-in parameters for the current session.
    private get vsCodeSignInParams(): VSCodeSignInParams | undefined {
        const value = sessionStorage.getItem(vscodeSignInKey);
        return value !== null ? JSON.parse(value) : undefined;
    }

    // Updates vscode.dev sign-in parameters for the current session.
    private set vsCodeSignInParams(value: VSCodeSignInParams | undefined) {
        if (value) {
            this.trace('vsCodeSignInParams: Storing parameters in session storage.');
            sessionStorage.setItem(vscodeSignInKey, JSON.stringify(value));
        } else {
            this.trace('vsCodeSignInParams: Remove parameters from storage.');
            sessionStorage.removeItem(vscodeSignInKey);
        }
    }

    // Returns currently authenticated user, if present.
    getUser(): User | undefined {
        if (isNonEmptyString(this.token)) {
            this.trace('getUser: Found valid session token.');
            return decodeSessionToken(this.token);
        } else {
            this.trace('getUser: No valid session token found.');
            return undefined;
        }
    }

    // Initiates sign in flow for a user.
    async signIn(type: AuthType, rememberMe: boolean, redirectTo?: string): Promise<never> {
        this.rememberMe = rememberMe;
        if (redirectTo === undefined || isAbsoluteUrl(redirectTo)) {
            this.signInRoute = redirectTo;
        } else {
            this.signInRoute = new URL(getRouteUrl(redirectTo), window.location.origin).toString();
        }

        this.trace('signIn: Creating new auth session and redirecting to provider UI.');
        this.redirect(await this.startSession(type, rememberMe));
    }

    // Returns the access token for the current user.
    async getAccessToken(): Promise<string> {
        assert.isDefined(this.token, 'No valid session token found.');
        this.trace('getAccessToken: Found valid session token.');
        return this.token;
    }

    // Initiates sign out flow for a user.
    async signOut() {
        let wasSignedIn = false;
        if (isNonEmptyString(this.token)) {
            wasSignedIn = true;
            this.trace('signOut: Closing auth session.');
            await this.endSession(this.token);
        }

        this.trace('signOut: Clearing storage data.');
        this.token = undefined;
        this.rememberMe = false;
        this.vsCodeSignInParams = undefined;

        if (wasSignedIn) {
            // open vscode.dev/edu to sign out of the extension
            // We replace the current page so that we don't pollute the navigation stack.
            const signOutUrl = new VSCodeUrl(apiEnvironment, {
                signOut: 'true',
            });
            this.redirect(signOutUrl.toString(), true);
        }
    }

    // Handles redirect from Active Directory (Entra) authentication provider.
    async handleEntraRedirect(): Promise<void> {
        if (window.location.pathname !== getRouteUrl('/redirect')) {
            return;
        }

        const params = new URLSearchParams(window.location.search);
        const code = params.get('code');
        if (!isNonEmptyString(code)) {
            this.warning("handleEntraRedirect: Missing 'code' query parameter.");
            return;
        }

        const state = params.get('state');
        if (!isNonEmptyString(state)) {
            this.warning("handleEntraRedirect: Missing 'state' query parameter.");
            return;
        }

        this.trace('handleEntraRedirect: Exchanging Entra authentication code for session token.');
        this.token = await this.exchangeSession(code, state);

        const signInParams = this.vsCodeSignInParams;
        if (signInParams) {
            this.trace('handleEntraRedirect: Found VSCode sign-in info, creating OTAC.');
            this.vsCodeSignInParams = undefined;
            this.redirect(await this.newOtac(this.token, signInParams), true);
        }

        const signInRoute = this.signInRoute;
        if (isNonEmptyString(signInRoute)) {
            this.trace('handleEntraRedirect: Found sign-in route, redirecting.');
            this.signInRoute = undefined;
            this.redirect(signInRoute, true);
        }
    }

    // True if we need to show the sign-in UI for an incoming shared link
    requiresSharedLinkSignIn(): boolean {
        if (!isNonEmptyString(this.token) && isNonEmptyString(getSharedLinkId(window.location.pathname))) {
            this.trace('Incoming shared link requires sign-in.');
            return true;
        }

        return false;
    }

    // Handles sign-in request from extension of vscode.dev.
    // Returns true if sign-in UI should be shown, false if not.
    async handleVSCodeSilentSignIn(): Promise<boolean> {
        if (window.location.pathname !== getRouteUrl('/signin')) {
            return false;
        }

        const params = new URLSearchParams(window.location.search);
        const requestId = params.get(KnownAuthQueryParams.requestId);
        if (!isNonEmptyString(requestId)) {
            this.warning("handleVSCodeSignIn: Missing 'requestId' parameter.");
            return false;
        }

        let redirectUrl = params.get(KnownAuthQueryParams.redirectUri);
        if (!isNonEmptyString(redirectUrl)) {
            this.warning("handleVSCodeSignIn: Missing 'redirectUrl' parameter.");
            return false;
        }

        try {
            redirectUrl = decodeURIComponent(redirectUrl);
            this.trace(`handleVSCodeSignIn: Decoded 'redirectUrl' parameter: ${redirectUrl}.`);
            if (!isSupportedRedirectUri(redirectUrl)) {
                this.warning("handleVSCodeSignIn: Invalid 'redirectUrl' protocol and/or origin.");
                return false;
            }
        } catch (e) {
            this.warning("handleVSCodeSignIn: Invalid 'redirectUrl' parameter.");
            return false;
        }

        const codeChallenge = params.get(KnownAuthQueryParams.codeChallenge);
        if (!isNonEmptyString(codeChallenge)) {
            this.warning("handleVSCodeSignIn: Missing 'codeChallenge' parameter.");
            return false;
        }

        const signInParams = { requestId, redirectUrl, codeChallenge, rememberMe: this.rememberMe };
        if (isNonEmptyString(this.token)) {
            this.trace('handleVSCodeSignIn: Found valid session token, creating OTAC.');
            this.redirect(await this.newOtac(this.token, signInParams), true);
        } else {
            this.trace('handleVSCodeSignIn: No session token found, redirecting to sign-in UI.');
            this.vsCodeSignInParams = signInParams;
            this.signInRoute = undefined;
            return true;
        }
    }

    // Redirects to specified URL and throws Redirecting error.
    private redirect(url: string, replace?: boolean): never {
        this.trace(`Redirecting to url: ${url} replace: ${replace}`);
        if (replace === true) {
            window.location.replace(url);
        } else {
            window.location.assign(url);
        }
        throw new Redirecting();
    }

    // Invokes startSession API and returns provider sign-in URL.
    private async startSession(type: AuthType, rememberMe: boolean): Promise<string> {
        let result: string;
        const root = window.location.origin;
        const redirectUrl = new URL(getRouteUrl('/redirect'), root).toString();
        const options: StartSessionOptions = {
            authProvider: type.toLowerCase() as AuthProvider,
            redirectUrl: redirectUrl.toString(),
            longTerm: rememberMe,
        };

        this.trace('startSession: Creating new auth session.');
        await anonymousClient.startSession(options, {
            onResponse: ({ status, bodyAsText }) => {
                if (status === 200 && isNonEmptyString(bodyAsText)) {
                    result = bodyAsText;
                } else {
                    throw new Error(`Failed to obtain provider sign-in URL: ${status}.`);
                }
            },
        });

        return result!;
    }

    // Invokes exchangeSession API and returns session token.
    private async exchangeSession(code: string, state: string): Promise<string> {
        let result: string;

        this.trace('exchangeSession: Exchanging auth code for session token.');
        await anonymousClient.exchangeSession(state, code, {
            onResponse: ({ status, bodyAsText }) => {
                if (status === 200 && isNonEmptyString(bodyAsText)) {
                    result = bodyAsText;
                } else {
                    throw new Error(`Failed to obtain session token: ${status}.`);
                }
            },
        });

        return result!;
    }

    // Invokes endSession API.
    private async endSession(token: string): Promise<void> {
        this.trace('endSession: Closing auth session.');
        await anonymousClient.endSession({
            requestOptions: {
                customHeaders: { Authorization: `Bearer ${token}` },
            },
        });
    }

    // Invokes newOtac API and returns fully-populated redirect URI.
    private async newOtac(token: string, params: VSCodeSignInParams): Promise<string> {
        const { codeChallenge, redirectUrl, requestId, rememberMe } = params;
        let result: string;

        this.trace('redirectTokenToVSCode: Creating new OTAC from session token.');
        await anonymousClient.newOtac(rememberMe, {
            codeChallenge,
            requestOptions: {
                customHeaders: { Authorization: `Bearer ${token}` },
            },
            onResponse: ({ status, bodyAsText, headers }) => {
                if (status === 200 && isNonEmptyString(bodyAsText)) {
                    const url = new URL(redirectUrl);
                    url.searchParams.append(KnownAuthQueryParams.Otac, bodyAsText);
                    url.searchParams.append(KnownAuthQueryParams.requestId, requestId);

                    const affinityRegion = headers.get('x-ms-affinity-region');
                    if (isNonEmptyString(affinityRegion)) {
                        url.searchParams.append(KnownAuthQueryParams.region, affinityRegion);
                    }

                    result = url.toString();
                } else {
                    throw new Error(`Failed to create OTAC: ${status}.`);
                }
            },
        });

        return result!;
    }
}

/**
 * The redirect URI is supported if it starts with one of the following:
 * - https://vscode.dev/edu (SSO)
 * - https://insiders.vscode.dev/edu (SSO)
 * - https://insiders.vscode.dev/callback?vscode-authority=ms-edu.vscode-learning (Browser Extension)
 * - https://vscode.dev/callback?vscode-authority=ms-edu.vscode-learning (Browser Extension)
 * - vscode://ms-edu.vscode-learning (Desktop Extension)
 * - vscode-insiders://ms-edu.vscode-learning (Desktop Extension)
 * - http://localhost (for local or PPE development)
 * This method is used to prevent logins from being initiated from unknown sources.
 * @param redirectUri The redirect URI to check if it is supported.
 * @returns True if the redirect URI is supported, false otherwise.
 */
export function isSupportedRedirectUri(redirectUri: string): boolean {
    const { protocol, origin, pathname, host, searchParams, hostname } = new URL(redirectUri);

    // vscode.dev/edu
    if (origin === 'https://vscode.dev' && pathname === '/edu') {
        return true;
    }

    // insiders.vscode.dev/edu
    if (origin === 'https://insiders.vscode.dev' && pathname === '/edu') {
        return true;
    }

    // vscode://ms-edu.vscode-learning
    // TODO: JSDOM currently shows the pathname as '' instead of '//ms-edu.vscode-learning'
    // whereas the browser shows '//ms-edu.vscode-learning' and host as ''. We need to migrate to a non-jsdom test env.
    // which cannot be done in create-react-app rewired as that is hard coded in.
    if (protocol === 'vscode:' && (host === 'ms-edu.vscode-learning' || pathname === '//ms-edu.vscode-learning')) {
        return true;
    }

    // vscode-insiders://ms-edu.vscode-learning
    // TODO: JSDOM currently shows the pathname as '' instead of '//ms-edu.vscode-learning'
    // whereas the browser shows '//ms-edu.vscode-learning' and host as ''. We need to migrate to a non-jsdom test env.
    // which cannot be done in create-react-app rewired as that is hard coded in.
    if (
        protocol === 'vscode-insiders:' &&
        (host === 'ms-edu.vscode-learning' || pathname === '//ms-edu.vscode-learning')
    ) {
        return true;
    }

    // https://insiders.vscode.dev/callback
    if (origin === 'https://insiders.vscode.dev' && pathname === '/callback') {
        const authority = searchParams.get('vscode-authority');
        return authority === 'ms-edu.vscode-learning';
    }

    // https://vscode.dev/callback
    if (origin === 'https://vscode.dev' && pathname === '/callback') {
        const authority = searchParams.get('vscode-authority');
        return authority === 'ms-edu.vscode-learning';
    }

    // http://localhost For local development
    if (hostname === 'localhost' && apiEnvironment === ApiEnvironment.PreProduction) {
        return true;
    }

    return false;
}
