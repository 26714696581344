import { TableCellLayout } from '@fluentui/react-components';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Curriculum } from '../../models';

// Curriculum type cell properties.
export type TypeCellProps = Readonly<{
    type: Curriculum['type'];
}>;

// Curriculum type data grid cell contents.
export default (props: TypeCellProps) => {
    const { type } = props;
    return (
        <TableCellLayout>
            {type === 'curriculum' && (
                <FormattedMessage
                    description="Text in Type column of activities data grid."
                    defaultMessage="Curriculum"
                />
            )}
            {type === 'guided-tutorial' && (
                <FormattedMessage
                    description="Text in Type column of activities data grid."
                    defaultMessage="Guided tutorial"
                />
            )}
        </TableCellLayout>
    );
};
