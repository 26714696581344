import { Button, Menu, MenuList, MenuPopover, MenuTrigger, TableCellLayout } from '@fluentui/react-components';
import {
    CloudDismissRegular,
    CloudRegular,
    EditRegular,
    LinkRegular,
    MoreHorizontalRegular,
    OpenRegular,
    ShareRegular,
} from '@fluentui/react-icons';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { PublishedProject } from '../../models';
import { useAppStyles } from '../../styles';
import { IntlProps, TrackedButton, TrackedMenuItem } from '../common';
import { useOpenProjectUrl } from '../../data';

// Actions cell properties.
export type ActionsCellProps = IntlProps<{
    projectId: string;
    state: PublishedProject['state'];
    publicUrl: string;
    lastUpdatePublished?: boolean;
    onPublish: () => void;
    onUnpublish: () => void;
    onShare: () => void;
}>;

// Actions data grid cell contents.
export default injectIntl((props: ActionsCellProps) => {
    const { intl, projectId, state, publicUrl, lastUpdatePublished, onPublish, onUnpublish, onShare } = props;
    const openProjectUrl = useOpenProjectUrl(projectId).toString();
    const styles = useAppStyles();
    return (
        <TableCellLayout>
            <TrackedButton
                trackAction="open"
                className={styles.mobileHidden}
                as="a"
                appearance="subtle"
                icon={<OpenRegular />}
                href={publicUrl}
                target="_blank"
                title={intl.formatMessage({
                    description: 'Open in browser menu item',
                    defaultMessage: 'Open in browser',
                })}
            />
            <TrackedButton
                trackAction="share"
                className={styles.mobileHidden}
                appearance="subtle"
                icon={<ShareRegular />}
                onClick={onShare}
                title={intl.formatMessage({
                    description: 'Share menu item',
                    defaultMessage: 'Share your work',
                })}
            />
            <TrackedButton
                trackAction="edit"
                className={styles.mobileHidden}
                as="a"
                appearance="subtle"
                icon={<EditRegular />}
                href={openProjectUrl}
                target={'_blank'}
                title={intl.formatMessage({
                    description: 'Edit in VS Code menu item',
                    defaultMessage: 'Edit in VS Code',
                })}
            />
            <Menu>
                <MenuTrigger>
                    <Button
                        appearance="subtle"
                        icon={<MoreHorizontalRegular />}
                        title={intl.formatMessage({
                            description: 'More actions menu item',
                            defaultMessage: 'More actions',
                        })}
                    />
                </MenuTrigger>
                <MenuPopover>
                    <MenuList>
                        <TrackedMenuItem
                            trackAction="open"
                            className={styles.mobileOnly}
                            icon={<OpenRegular />}
                            onClick={() => {
                                window.location.href = publicUrl;
                            }}
                        >
                            <FormattedMessage description="Menu item text" defaultMessage="Open in browser" />
                        </TrackedMenuItem>
                        <TrackedMenuItem
                            trackAction="share"
                            className={styles.mobileOnly}
                            icon={<ShareRegular />}
                            onClick={onShare}
                        >
                            <FormattedMessage description="Menu item text" defaultMessage="Share your work" />
                        </TrackedMenuItem>
                        <TrackedMenuItem
                            trackAction="edit"
                            className={styles.mobileOnly}
                            icon={<EditRegular />}
                            onClick={() => window.open(openProjectUrl, '_blank')}
                        >
                            <FormattedMessage description="Menu item text" defaultMessage="Edit in VS Code" />
                        </TrackedMenuItem>
                        <TrackedMenuItem
                            trackAction="copy-address"
                            disabled={state === 'Unpublishing'}
                            icon={<LinkRegular />}
                            onClick={() => navigator.clipboard.writeText(publicUrl)}
                        >
                            <FormattedMessage
                                description="Copy address menu option"
                                defaultMessage="Copy web address"
                            />
                        </TrackedMenuItem>
                        {lastUpdatePublished !== true && (
                            <TrackedMenuItem
                                trackAction="publish"
                                disabled={state !== 'Created'}
                                icon={<CloudRegular />}
                                onClick={onPublish}
                            >
                                <FormattedMessage
                                    description="Publish menu option"
                                    defaultMessage="Publish recent changes"
                                />
                            </TrackedMenuItem>
                        )}
                        <TrackedMenuItem
                            trackAction="unpublish"
                            disabled={state !== 'Created'}
                            icon={<CloudDismissRegular />}
                            onClick={onUnpublish}
                        >
                            <FormattedMessage description="Unpublish menu option" defaultMessage="Unpublish" />
                        </TrackedMenuItem>
                    </MenuList>
                </MenuPopover>
            </Menu>
        </TableCellLayout>
    );
});
