import { TableCellLayout } from '@fluentui/react-components';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Curriculum } from '../../models';

// Curriculum platform cell properties.
export type PlatformCellProps = Readonly<{
    platform: Curriculum['platform'];
}>;

// Curriculum platform data grid cell contents.
export default (props: PlatformCellProps) => {
    const { platform } = props;
    return (
        <TableCellLayout>
            {platform === 'html' && (
                <FormattedMessage
                    description="Text in Language column of activities data grid."
                    defaultMessage="Web Dev"
                />
            )}
            {platform === 'python' && (
                <FormattedMessage
                    description="Text in Language column of activities data grid."
                    defaultMessage="Python"
                />
            )}
        </TableCellLayout>
    );
};
