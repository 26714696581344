import { VsCodeEduClient, VsCodeEduClientExt, assert } from '@microsoft/vscodeedu-api';
import { produce } from 'immer';
import React, { Dispatch, createContext, useCallback, useReducer } from 'react';
import { injectIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { createSelector } from 'reselect';
import { User } from '../models';
import { useAsyncRunner } from '../utilities/async-runner';
import { traceEvent, trackWarning } from '../utilities/diagnostics';
import { downloadBlob } from '../utilities/download';
import { apiEnvironment } from './config-context';
import { ContextProps } from './context-props';
import { StoreState } from './store-state';
import { AuthType, Redirecting, VSCodeEduIdentityProvider } from './vsce-identity-provider';

// User store.
export type UserStore = Readonly<{
    state: StoreState | 'SigningIn' | 'SigningOut' | 'Downloading' | 'Deleting';
    user?: User;
}>;

// User store action.
export type UserAction =
    | { type: 'SignIn'; provider: AuthType; rememberMe: boolean; signUp: boolean; navigateTo?: string }
    | { type: 'SignInError' }
    | { type: 'SignOut' }
    | { type: 'SignOutResult' }
    | { type: 'SignOutError' }
    | { type: 'DownloadData' }
    | { type: 'DownloadDataResult' }
    | { type: 'DownloadDataError' }
    | { type: 'DeleteAccount' }
    | { type: 'DeleteAccountError' };

// User context.
export const UserContext = createContext<[state: UserStore, reducer: Dispatch<UserAction>]>(undefined!);

// Static instance of identity provider.
const provider = new VSCodeEduIdentityProvider();

// User context provider.
export const UserContextProvider = injectIntl((props: ContextProps) => {
    const { intl } = props;
    const navigate = useNavigate();
    const runner = useAsyncRunner();

    // Signs the user in.
    const signIn = useCallback(
        async (type: AuthType, rememberMe: boolean, navigateTo?: string) =>
            runner.run({
                task: async () => {
                    try {
                        // Initiate sign-in flow.
                        await provider.signIn(type, rememberMe, navigateTo ?? '/');
                    } catch (e) {
                        if (e instanceof Redirecting) {
                            // Ignore redirection exception.
                            return;
                        } else {
                            // Rethrow error which will be handled by the runner.
                            throw e;
                        }
                    }
                },
                onError: () => {
                    dispatch({ type: 'SignInError' });
                },
                errorMessage: intl.formatMessage({
                    description: 'Error message for an async operation.',
                    defaultMessage: 'An error ocurred while signing in.',
                }),
            }),
        [runner, intl]
    );

    // Signs the user out.
    const signOut = useCallback(
        async () =>
            runner.run({
                task: async () => {
                    try {
                        await provider.signOut();
                        dispatch({ type: 'SignOutResult' });
                        navigate('/');
                    } catch (e) {
                        if (e instanceof Redirecting) {
                            // Ignore redirection exception.
                            return;
                        } else {
                            // Rethrow error which will be handled by the runner.
                            throw e;
                        }
                    }
                },
                onError: () => {
                    dispatch({ type: 'SignOutError' });
                },
                progressMessage: intl.formatMessage({
                    description: 'Progress message for an async operation.',
                    defaultMessage: 'Signing out.',
                }),
                successMessage: intl.formatMessage({
                    description: 'Success message for an async operation.',
                    defaultMessage: 'You have been successfully signed out of your account.',
                }),
                errorMessage: intl.formatMessage({
                    description: 'Error message for an async operation.',
                    defaultMessage: 'An error ocurred while signing out.',
                }),
            }),
        [intl, navigate, runner]
    );

    // Downloads user data.
    const downloadData = useCallback(
        async (client: VsCodeEduClient) =>
            runner.run({
                task: async () => {
                    const { blobBody } = await client.downloadUserData('me');
                    if (blobBody) {
                        downloadBlob(await blobBody, 'data.zip');
                    }
                    dispatch({ type: 'DownloadDataResult' });
                },
                onError: () => {
                    dispatch({ type: 'DownloadDataError' });
                },
                progressMessage: intl.formatMessage({
                    description: 'Progress message for an async operation.',
                    defaultMessage: 'Preparing your data for download.',
                }),
                successMessage: intl.formatMessage({
                    description: 'Success message for an async operation.',
                    defaultMessage: 'Your data has been successfully downloaded.',
                }),
                errorMessage: intl.formatMessage({
                    description: 'Error message for an async operation.',
                    defaultMessage: 'An error ocurred while downloading personal data.',
                }),
            }),
        [intl, runner]
    );

    // Deletes user account data and then signs out.
    const deleteAccount = useCallback(
        async (client: VsCodeEduClient) =>
            runner.run({
                task: async () => {
                    await client.deleteUser('me');
                    await provider.signOut();
                    dispatch({ type: 'SignOutResult' });
                },
                onError: () => {
                    dispatch({ type: 'DeleteAccountError' });
                },
                progressMessage: intl.formatMessage({
                    description: 'Progress message for an async operation.',
                    defaultMessage: 'Deleting all data associated with your account.',
                }),
                successMessage: intl.formatMessage({
                    description: 'Success message for an async operation.',
                    defaultMessage: 'All data associated with your account has been successfully deleted.',
                }),
                errorMessage: intl.formatMessage({
                    description: 'Error message for an async operation.',
                    defaultMessage: 'An error ocurred while deleting account information.',
                }),
            }),
        [intl, runner]
    );

    // Store action reducer - updates store state and kicks off async actions.
    const reducer = useCallback(
        (store: UserStore, action: UserAction) =>
            produce(store, (draft) => {
                traceEvent('user-context.action', { type: action.type, state: draft.state });
                switch (action.type) {
                    case 'SignIn':
                        if (draft.state === 'Loaded') {
                            signIn(action.provider, action.rememberMe, action.navigateTo);
                            draft.state = 'SigningIn';
                        } else {
                            trackWarning('user-context: cannot process SignIn in current state.');
                        }
                        break;
                    case 'SignInError':
                        draft.state = 'Loaded';
                        break;
                    case 'SignOut':
                        if (draft.state === 'Loaded') {
                            signOut();
                            draft.state = 'SigningOut';
                        } else {
                            trackWarning('user-context: cannot process SignOut in current state.');
                        }
                        break;
                    case 'SignOutResult':
                        draft.state = 'Loaded';
                        draft.user = undefined;
                        break;
                    case 'SignOutError':
                        draft.state = 'Loaded';
                        break;
                    case 'DownloadData':
                        if (draft.state === 'Loaded') {
                            downloadData(getUserClient(store)!);
                            draft.state = 'Downloading';
                        } else {
                            trackWarning('user-context: cannot process DownloadData in current state.');
                        }
                        break;
                    case 'DownloadDataResult':
                        draft.state = 'Loaded';
                        break;
                    case 'DownloadDataError':
                        draft.state = 'Loaded';
                        break;
                    case 'DeleteAccount':
                        if (draft.state === 'Loaded') {
                            deleteAccount(getUserClient(store)!);
                            draft.state = 'Deleting';
                        } else {
                            trackWarning('user-context: cannot process DeleteAccount in current state.');
                        }
                        break;
                    case 'DeleteAccountError':
                        draft.state = 'Loaded';
                        break;
                    default:
                        assert.unreachable(action, 'action');
                }
            }),
        [signIn, signOut, downloadData, deleteAccount]
    );

    const [state, dispatch] = useReducer(reducer, { state: 'Loaded', user: provider.getUser() });
    return <UserContext.Provider value={[state, dispatch]}>{props.children}</UserContext.Provider>;
});

// Selector to get currently signed in user.
export const getUser = createSelector([(store: UserStore) => store], (store) => store.user);

// Selector which returns whether there is a user signed in.
export const getIsSignedIn = createSelector([getUser], (user) => !!user);

// Selector to get currently signed in user's ID.
export const getUserId = createSelector([getUser], (user) => user?.oid);

// Selector to create a VS Code Edu client for the currently signed in user.
export const getUserClient = createSelector([getIsSignedIn], (signedIn) => {
    if (!signedIn) {
        return undefined;
    } else {
        const client = new VsCodeEduClientExt(apiEnvironment);
        client.pipeline.addPolicy({
            name: 'authorization',
            sendRequest: async (request, next) => {
                const token = await provider.getAccessToken();
                request.headers.set('authorization', `Bearer ${token}`);
                return await next(request);
            },
        });

        return client;
    }
});
