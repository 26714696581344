import {
    Button,
    Divider,
    Link,
    Menu,
    MenuList,
    MenuPopover,
    MenuTrigger,
    ProgressBar,
    mergeClasses,
} from '@fluentui/react-components';
import {
    ArrowDownloadRegular,
    BookRegular,
    MoreVerticalRegular,
    PersonFeedbackRegular,
    SignOutRegular,
} from '@fluentui/react-icons';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useConfig, useOpenCourseUrl } from '../../data';
import { UserProgress } from '../../models';
import { useAppStyles } from '../../styles';
import { Indicator, IntlProps, ProjectTypeLabel, TrackContext, TrackedButton, TrackedMenuItem } from '../common';

// Course progress card properties.
export type CourseProgressCardProps = IntlProps<{
    state: UserProgress['state'];
    courseId: string;
    title: string;
    cardImage: string;
    codeType: string;
    started: boolean;
    completedUnits: number;
    totalUnits: number;
    currentPosition?: string;
    onDownload: () => void;
    onUnenroll: () => void;
}>;

// User progress on a course card.
export default injectIntl((props: CourseProgressCardProps) => {
    const {
        intl,
        state,
        courseId,
        cardImage,
        codeType,
        title,
        started,
        completedUnits,
        totalUnits,
        currentPosition,
        onDownload,
        onUnenroll,
    } = props;

    const styles = useAppStyles();
    const navigate = useNavigate();
    const { feedbackUrl } = useConfig();
    const openCourseUrl = useOpenCourseUrl(courseId)?.toString();
    const completed = completedUnits === totalUnits;

    return (
        <TrackContext.Provider value={{ container: 'course-progress-card', props: { courseId } }}>
            <div className={mergeClasses(styles.card, styles.cardThreeColumns)}>
                <div className={styles.cardMedia}>
                    <div className={styles.cardMediaImage} style={{ backgroundImage: `url('${cardImage}')` }} />
                </div>
                <div className={styles.cardContent}>
                    <div className={styles.flexRowCentered}>
                        <ProjectTypeLabel
                            className={mergeClasses(styles.cardCategory, styles.flexGrow1)}
                            type={codeType}
                        />
                        <Menu>
                            <MenuTrigger disableButtonEnhancement>
                                <Button
                                    icon={<MoreVerticalRegular />}
                                    appearance="subtle"
                                    title={intl.formatMessage({
                                        description: 'Card menu more icon aria label',
                                        defaultMessage: 'More options',
                                    })}
                                />
                            </MenuTrigger>
                            <MenuPopover>
                                <MenuList>
                                    <TrackedMenuItem
                                        trackAction="feedback"
                                        icon={<PersonFeedbackRegular />}
                                        onClick={() => window.open(feedbackUrl, '_blank')}
                                    >
                                        <FormattedMessage
                                            description="Course card 'Send feedback' menu option"
                                            defaultMessage="Send feedback"
                                        />
                                    </TrackedMenuItem>
                                    <TrackedMenuItem
                                        trackAction="course-info"
                                        icon={<BookRegular />}
                                        onClick={() => navigate(`/courses/${courseId}`)}
                                    >
                                        <FormattedMessage
                                            description="Course card 'View course info' menu option"
                                            defaultMessage="View course info"
                                        />
                                    </TrackedMenuItem>
                                    <TrackedMenuItem
                                        trackAction="download"
                                        icon={<ArrowDownloadRegular />}
                                        onClick={onDownload}
                                    >
                                        <FormattedMessage
                                            description="Course card 'Download' menu option"
                                            defaultMessage="Download my code"
                                        />
                                    </TrackedMenuItem>
                                    {started && (
                                        <>
                                            <Divider />
                                            <TrackedMenuItem
                                                trackAction="unenroll"
                                                icon={<SignOutRegular />}
                                                onClick={onUnenroll}
                                            >
                                                <FormattedMessage
                                                    description="Course card 'Unenroll' menu option"
                                                    defaultMessage="Unenroll"
                                                />
                                            </TrackedMenuItem>
                                        </>
                                    )}
                                </MenuList>
                            </MenuPopover>
                        </Menu>
                    </div>
                    <Link
                        className={mergeClasses(styles.cardTitle, styles.linkButton)}
                        onClick={() => navigate(`/courses/${courseId}`)}
                    >
                        {title}
                    </Link>
                    <div className={mergeClasses(styles.cardDescription, styles.flexGrow1)}>
                        {currentPosition !== undefined && (
                            <>
                                <span className={styles.textSemibold}>
                                    <FormattedMessage
                                        description="Label for current position within a course."
                                        defaultMessage="Current lesson:"
                                    />
                                    &nbsp;
                                </span>
                                {currentPosition}
                            </>
                        )}
                    </div>
                    <div className={styles.cardFooter}>
                        {state === 'Deleting' && (
                            <Indicator type="spinner">
                                <FormattedMessage
                                    description="Course card 'Unenrolling' status message"
                                    defaultMessage="Unenrolling"
                                />
                            </Indicator>
                        )}
                        {state !== 'Deleting' && (
                            <>
                                {!started && (
                                    <FormattedMessage
                                        description="Course card 'Not started' status message"
                                        defaultMessage="Not started"
                                    />
                                )}
                                {started && !completed && (
                                    <FormattedMessage
                                        description="Course card unit completion text"
                                        defaultMessage="{COMPLETED} of {TOTAL} units completed"
                                        values={{
                                            COMPLETED: completedUnits,
                                            TOTAL: totalUnits,
                                        }}
                                    />
                                )}
                                {completed && (
                                    <FormattedMessage
                                        description="Course card unit completion text"
                                        defaultMessage="COMPLETED"
                                    />
                                )}
                            </>
                        )}
                        <div className={styles.flexGrow1} />
                        {state !== 'Deleting' && openCourseUrl !== undefined && (
                            <TrackedButton
                                trackAction="resume"
                                appearance={completed ? 'secondary' : 'primary'}
                                onClick={() => {
                                    window.location.href = openCourseUrl;
                                }}
                            >
                                {!started && (
                                    <FormattedMessage description="Course card 'Start' button" defaultMessage="Start" />
                                )}
                                {started && !completed && (
                                    <FormattedMessage
                                        description="Course card 'Resume' button"
                                        defaultMessage="Resume"
                                    />
                                )}
                                {completed && (
                                    <FormattedMessage
                                        description="Course card 'Review' button"
                                        defaultMessage="Review"
                                    />
                                )}
                            </TrackedButton>
                        )}
                    </div>
                </div>
                <ProgressBar className={styles.cardProgress} value={completedUnits} max={totalUnits} />
            </div>
        </TrackContext.Provider>
    );
});
