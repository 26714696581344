import {
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogProps,
    DialogSurface,
    DialogTitle,
    Link,
} from '@fluentui/react-components';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { WarningImage } from '../../icons/status';
import { useAppStyles } from '../../styles';
import { TrackContext } from '../common';
import { CancelButton, CloseButton, ConfirmButton, ConfirmationField } from '../dialogs';

// Unenroll from course dialog properties.
export type UnenrollDialogProps = Readonly<
    Partial<DialogProps> & {
        courseTitle: string;
        onDownload: () => void;
        onUnenroll: () => void;
    }
>;

// Unenroll from course confirmation dialog.
export default (props: UnenrollDialogProps) => {
    const { courseTitle, onDownload, onUnenroll, open } = props;
    const [confirmed, setConfirmed] = useState(false);
    const styles = useAppStyles();

    // Reset confirmation input text value on reopening the dialog.
    const [value, setValue] = useState('');
    useEffect(() => setValue(''), [open]);

    return (
        <TrackContext.Provider value={{ container: 'unenroll-dialog' }}>
            <Dialog {...props}>
                <DialogSurface>
                    <DialogBody>
                        <DialogTitle action={<CloseButton tabIndex={-1} />}>
                            <FormattedMessage
                                description="Unenroll from course dialog title."
                                defaultMessage="Unenroll from course"
                            />
                        </DialogTitle>
                        <DialogContent className={styles.flexRowCentered}>
                            <WarningImage className={styles.dialogImage} />
                            <div className={styles.dialogContent}>
                                <div>
                                    <FormattedMessage
                                        description="Confirmation text on unenroll from course."
                                        defaultMessage="Are you sure you want to unenroll from ''{COURSE_TITLE}'' course?"
                                        values={{ COURSE_TITLE: courseTitle }}
                                    />
                                </div>
                                <div>
                                    <FormattedMessage
                                        description="Title for a list of items that will be lost when unenrolling from a course."
                                        defaultMessage="You will permanently lose:"
                                    />
                                    <ul>
                                        <li>
                                            <FormattedMessage
                                                description="An item in the list of things that will be lost when unenrolling from a course."
                                                defaultMessage="All progress towards completion of the course"
                                            />
                                        </li>
                                        <li>
                                            <FormattedMessage
                                                description="An item in the list of things that will be lost when unenrolling from a course."
                                                defaultMessage="All code you have written for this course"
                                            />
                                        </li>
                                        <li>
                                            <FormattedMessage
                                                description="An item in the list of things that will be lost when unenrolling from a course."
                                                defaultMessage="Responses to quizzes or assessments within the course"
                                            />
                                        </li>
                                    </ul>
                                    <div className={styles.textSemibold}>
                                        <FormattedMessage
                                            description="Bolded warning text on the unenroll from a course."
                                            defaultMessage="Once unenrolled, your progress and work cannot be recovered!"
                                        />
                                    </div>
                                </div>
                                <br />
                                <div>
                                    <FormattedMessage
                                        description="Message on the unenroll from course dialog. DOWNLOAD_LINK is a link to download user's code with text 'Click here'."
                                        defaultMessage="{DOWNLOAD_LINK} to download your code for the course before it is deleted."
                                        values={{
                                            DOWNLOAD_LINK: (
                                                <Link onClick={onDownload}>
                                                    <FormattedMessage
                                                        description="Link text on the unenroll from course dialog to download user's code."
                                                        defaultMessage="Click here"
                                                    />
                                                </Link>
                                            ),
                                        }}
                                    />
                                </div>
                                <ConfirmationField
                                    initialValue={value}
                                    onChange={setConfirmed}
                                    confirmationText="UNENROLL"
                                />
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <CancelButton />
                            <ConfirmButton className={styles.buttonDanger} onClick={onUnenroll} disabled={!confirmed}>
                                <FormattedMessage
                                    description="Unenroll from course dialog confirmation button text."
                                    defaultMessage="Unenroll from course"
                                />
                            </ConfirmButton>
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        </TrackContext.Provider>
    );
};
