import { VSCodeUrl, assert, compareLocale, isNonEmptyString, models } from '@microsoft/vscodeedu-api';
import { getProfileFileName } from '@microsoft/vscodeedu-common';
import { produce } from 'immer';
import React, { Dispatch, createContext, useCallback, useContext, useReducer } from 'react';
import { injectIntl } from 'react-intl';
import { createSelector } from 'reselect';
import useAsyncEffect from 'use-async-effect';
import { ContextProps, StoreState, UserContext, anonymousClient, apiEnvironment, getUserId, useConfig } from '.';
import { Course } from '../models';
import { getContentUrl, getFetchUrl } from '../utilities';
import { useAsyncRunner } from '../utilities/async-runner';
import { traceEvent } from '../utilities/diagnostics';
import { useTheme } from './theme-context';

// Course store.
export type CourseStore = Readonly<{
    state: StoreState;
    items: Course[];
}>;

// Course store action.
export type CourseAction =
    | { type: 'LoadResult'; items: Course[] }
    | { type: 'LoadError' }
    | { type: 'LoadUnitsResult'; courseId: string; units: models.Unit[]; duration: number };

// Course context.
export const CourseContext = createContext<[state: CourseStore, reducer: Dispatch<CourseAction>]>(undefined!);

// Course context provider.
export const CourseContextProvider = injectIntl((props: ContextProps) => {
    const { intl } = props;
    const { visibleCourses, contentBaseUrl } = useConfig();
    const runner = useAsyncRunner();

    // Converts data model to view model.
    const toModel = useCallback(
        (data: models.Course) => {
            return {
                ...data,
                cardImage: isNonEmptyString(data.cardImage)
                    ? getContentUrl(contentBaseUrl, data.cardImage)
                    : getFetchUrl('/course-card.png'),
                type: data.profileName === 'tutorial' ? 'guided-tutorial' : 'curriculum',
                // TODO: remove the following line once API server is deployed to PROD
                codeType: data.codeType ?? (data.id === 'intro-to-web-dev' ? 'html' : 'python'),
                level: data.level ?? 'beginner',
            } as Course;
        },
        [contentBaseUrl]
    );

    // Populate courses on load or user changes (due to course links).
    useAsyncEffect(
        () =>
            runner.run({
                task: async () => {
                    const data = await anonymousClient.listCourses();

                    // Filter courses based on config settings.
                    const filtered = !visibleCourses.length
                        ? data
                        : data.filter(({ id }) => visibleCourses.includes(id));

                    dispatch({ type: 'LoadResult', items: filtered.map(toModel) });
                },
                onError: () => {
                    dispatch({ type: 'LoadError' });
                },
                errorMessage: intl.formatMessage({
                    description: 'Error message for an async operation.',
                    defaultMessage: 'An error ocurred while loading list of available courses.',
                }),
            }),
        [toModel, runner, intl, visibleCourses]
    );

    // Loads specified course's units.
    const listCourseUnits = useCallback(
        async (courseId: string) =>
            runner.run({
                task: async () => {
                    const units = await anonymousClient.listCourseUnits(courseId);
                    units.sort((a, b) => a.order - b.order);
                    units.forEach((o) => o.lessons?.sort((a, b) => a.order - b.order));
                    const duration = units.reduce(
                        (pu, u) => pu + (u.lessons?.reduce((pl, l) => pl + (l.duration ?? 0), 0) ?? 0),
                        0
                    );
                    dispatch({ type: 'LoadUnitsResult', courseId, units, duration });
                },
                errorMessage: intl.formatMessage({
                    description: 'Error message for an async operation.',
                    defaultMessage: 'An error ocurred while loading course details information.',
                }),
            }),
        [intl, runner]
    );

    // Store action reducer - updates store state and kicks off async actions.
    const reducer = useCallback(
        (store: CourseStore, action: CourseAction) =>
            produce(store, (draft) => {
                traceEvent('course-context.action', { type: action.type, state: draft.state });
                switch (action.type) {
                    case 'LoadResult': {
                        if (draft.state === 'Loaded') {
                            // Handle reload on user changes, but preserve unit data.
                            draft.items = action.items;
                            draft.items.forEach((o) => {
                                const item = store.items.find((c) => c.id === o.id);
                                if (item) {
                                    o.units ??= item.units;
                                    o.duration = item.duration;
                                }
                            });
                        } else {
                            // Initial load - load units.
                            action.items.forEach(({ id }) => listCourseUnits(id));
                            draft.state = 'Loaded';
                            draft.items = action.items;
                        }
                        break;
                    }
                    case 'LoadError':
                        draft.state = 'NotLoaded';
                        break;
                    case 'LoadUnitsResult': {
                        const item = draft.items.find(({ id }) => id === action.courseId);
                        if (item) {
                            item.units = action.units;
                            item.duration = action.duration;
                        }
                        break;
                    }
                    default:
                        assert.unreachable(action, 'action');
                }
            }),
        [listCourseUnits]
    );

    const [state, dispatch] = useReducer(reducer, { items: [], state: 'Loading' });
    return <CourseContext.Provider value={[state, dispatch]}>{props.children}</CourseContext.Provider>;
});

// Selector to get whether courses are currently loading.
export const getIsLoadingCourses = createSelector(
    [(state: CourseStore) => state.state],
    (state) => state === 'Loading'
);

// Selector to get specific course by ID.
export const getCourse = createSelector(
    [(state: CourseStore, _) => state.items, (_, courseId: string) => courseId],
    (items, courseId) => items.find(({ id }) => id === courseId)
);

// Selector that gets curriculum courses sorted by title.
export const getCurriculumCourses = createSelector([(store: CourseStore) => store.items], (items) =>
    items.filter((o) => o.type === 'curriculum').sort((a, b) => compareLocale(a.title, b.title))
);

// Selector that gets activity courses sorted by title.
export const getActivityCourses = createSelector([(store: CourseStore) => store.items], (items) =>
    items.filter((o) => o.type !== 'curriculum').sort((a, b) => compareLocale(a.title, b.title))
);

// Returns a URL to open a course in VS Code.
export const useOpenCourseUrl = (courseId: string) => {
    const [courseStore] = useContext(CourseContext);
    const course = getCourse(courseStore, courseId);

    const [userStore] = useContext(UserContext);
    const userId = getUserId(userStore);

    const { courseProfileName } = useConfig();
    const [{ profileTheme }] = useTheme();

    if (!course) {
        return undefined;
    } else {
        return VSCodeUrl.forCourse(apiEnvironment, course.id, {
            accountId: userId,
            profile: getProfileFileName(course.profileName ?? courseProfileName, profileTheme),
        });
    }
};
