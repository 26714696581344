import { assert } from '@microsoft/vscodeedu-api';
import { produce } from 'immer';
import React, { createContext, Dispatch, useCallback, useContext, useEffect, useReducer } from 'react';
import { injectIntl } from 'react-intl';
import { createSelector } from 'reselect';
import { ContextProps, getUserClient, getUserId, StoreState, UserContext } from '.';
import { Curriculum } from '../models';
import { downloadBlob } from '../utilities';
import { useAsyncRunner } from '../utilities/async-runner';
import { traceEvent } from '../utilities/diagnostics';

// Curricula store.
export type CurriculumStore = Readonly<{
    state: StoreState;
    items: Curriculum[];
}>;

// Curriculum store action.
export type CurriculumAction =
    | { type: 'Load' }
    | { type: 'Reload' }
    | { type: 'LoadResult'; items: Curriculum[] | undefined }
    | { type: 'LoadError' }
    | { type: 'Create'; curriculum: Partial<Curriculum> }
    | { type: 'CreateError'; curriculumId: string }
    | { type: 'Update'; curriculum: Curriculum }
    | { type: 'UpdateResult'; curriculum: Curriculum }
    | { type: 'UpdateError'; curriculumId: string; previous?: Curriculum }
    | { type: 'Publish'; curriculumId: string }
    | { type: 'Unpublish'; curriculumId: string }
    | { type: 'Delete'; curriculumId: string }
    | { type: 'DeleteResult'; curriculumId: string }
    | { type: 'Download'; curriculumId: string };

// Curriculum context.
export const CurriculumContext = createContext<[state: CurriculumStore, reducer: Dispatch<CurriculumAction>]>(
    undefined!
);

// Curriculum context provider.
export const CurriculumContextProvider = injectIntl((props: ContextProps) => {
    const { intl } = props;
    const [userStore] = useContext(UserContext);
    const userId = getUserId(userStore);
    const client = getUserClient(userStore);
    const runner = useAsyncRunner();

    // Respond to user change by reloading the data.
    useEffect(() => dispatch({ type: 'Reload' }), [userId]);

    // Loads user curriculum list.
    const listCurricula = useCallback(
        () => async () =>
            runner.run({
                task: async () => {
                    if (client) {
                        // TODO: const data = await client.listUserCurricula('me');
                        dispatch({ type: 'LoadResult', items: [] });
                    }
                },
                onError: () => {
                    dispatch({ type: 'LoadError' });
                },
                errorMessage: intl.formatMessage({
                    description: 'Error message for an async operation.',
                    defaultMessage: 'An error ocurred while loading activities.',
                }),
            }),
        [runner, intl, client]
    );

    // Creates a new curriculum.
    const createCurriculum = useCallback(
        async (curriculum: Curriculum) => {
            const { curriculumId, title } = curriculum;
            runner.run({
                task: async () => {
                    // TODO: const data = await client!.createUserCurriculum('me', curriculumId, { body: curriculumId });
                    const model: Curriculum = {
                        ...curriculum,
                        state: 'draft',
                        type: 'guided-tutorial',
                        platform: 'python',
                        lastUpdated: new Date(),
                    };

                    // TODO: window.location.href = model.openProjectUrl;
                    dispatch({ type: 'UpdateResult', curriculum: model });
                },
                onError: () => {
                    dispatch({ type: 'CreateError', curriculumId });
                },
                progressMessage: intl.formatMessage(
                    {
                        description: 'Progress message for an async operation.',
                        defaultMessage: 'Creating activity {ACTIVITY_TITLE}.',
                    },
                    { ACTIVITY_TITLE: title }
                ),
                successMessage: intl.formatMessage(
                    {
                        description: 'Success message for an async operation.',
                        defaultMessage: 'Successfully created activity {ACTIVITY_TITLE}.',
                    },
                    { ACTIVITY_TITLE: title }
                ),
                errorMessage: intl.formatMessage(
                    {
                        description: 'Error message for an async operation.',
                        defaultMessage: 'An error ocurred while creating activity {ACTIVITY_TITLE}.',
                    },
                    { ACTIVITY_TITLE: title }
                ),
            });
        },
        [intl, runner]
    );

    // Updates a curriculum.
    const updateCurriculum = useCallback(
        (curriculum: Curriculum, previous: Curriculum) => {
            const { curriculumId, title } = curriculum;
            runner.run({
                task: async () => {
                    // TODO: const data = await client!.createOrUpdateUserCurriculum('me', curriculumId, { body: curriculum });
                    const model = { ...curriculum, lastUpdated: new Date() };
                    dispatch({ type: 'UpdateResult', curriculum: model });
                },
                onError: () => {
                    dispatch({ type: 'UpdateError', curriculumId, previous });
                },
                progressMessage: intl.formatMessage(
                    {
                        description: 'Progress message for an async operation.',
                        defaultMessage: 'Updating activity {ACTIVITY_TITLE}.',
                    },
                    { ACTIVITY_TITLE: title }
                ),
                errorMessage: intl.formatMessage(
                    {
                        description: 'Error message for an async operation.',
                        defaultMessage: 'An error ocurred while updating activity {ACTIVITY_TITLE}.',
                    },
                    { ACTIVITY_TITLE: title }
                ),
            });
        },
        [intl, runner]
    );

    // Publishes a curriculum.
    const publishCurriculum = useCallback(
        (curriculum: Curriculum) => {
            const { curriculumId, title } = curriculum;
            runner.run({
                task: async () => {
                    // TODO: const data = await client!.publishUserCurriculum('me', curriculumId);
                    const model: Curriculum = {
                        ...curriculum,
                        state: 'published',
                        lastPublished: new Date(),
                        courseId: curriculumId,
                    };

                    dispatch({ type: 'UpdateResult', curriculum: model });
                },
                onError: () => {
                    dispatch({ type: 'UpdateError', curriculumId, previous: curriculum });
                },
                progressMessage: intl.formatMessage(
                    {
                        description: 'Progress message for an async operation.',
                        defaultMessage: 'Publishing activity {ACTIVITY_TITLE}.',
                    },
                    { ACTIVITY_TITLE: title }
                ),
                successMessage: intl.formatMessage(
                    {
                        description: 'Success message for an async operation.',
                        defaultMessage: 'Successfully published activity {ACTIVITY_TITLE}.',
                    },
                    { ACTIVITY_TITLE: title }
                ),
                errorMessage: intl.formatMessage(
                    {
                        description: 'Error message for an async operation.',
                        defaultMessage: 'An error ocurred while publishing activity {ACTIVITY_TITLE}.',
                    },
                    { ACTIVITY_TITLE: title }
                ),
            });
        },
        [intl, runner]
    );

    // Unpublishes a curriculum.
    const unpublishCurriculum = useCallback(
        (curriculum: Curriculum) => {
            const { curriculumId, title } = curriculum;
            runner.run({
                task: async () => {
                    // TODO: const data = await client!.unpublishUserCurriculum('me', curriculumId);
                    const model: Curriculum = {
                        ...curriculum,
                        state: 'draft',
                        lastPublished: undefined,
                        courseId: undefined,
                    };

                    dispatch({ type: 'UpdateResult', curriculum: model });
                },
                onError: () => {
                    dispatch({ type: 'UpdateError', curriculumId, previous: curriculum });
                },
                progressMessage: intl.formatMessage(
                    {
                        description: 'Progress message for an async operation.',
                        defaultMessage: 'Unpublishing activity {ACTIVITY_TITLE}.',
                    },
                    { ACTIVITY_TITLE: title }
                ),
                successMessage: intl.formatMessage(
                    {
                        description: 'Success message for an async operation.',
                        defaultMessage: 'Successfully unpublished activity {ACTIVITY_TITLE}.',
                    },
                    { ACTIVITY_TITLE: title }
                ),
                errorMessage: intl.formatMessage(
                    {
                        description: 'Error message for an async operation.',
                        defaultMessage: 'An error ocurred while unpublishing activity {ACTIVITY_TITLE}.',
                    },
                    { ACTIVITY_TITLE: title }
                ),
            });
        },
        [intl, runner]
    );

    // Deletes a curriculum.
    const deleteCurriculum = useCallback(
        (curriculum: Curriculum) => {
            const { curriculumId, title } = curriculum;
            runner.run({
                task: async () => {
                    // TODO: await client!.deleteUserCurriculum('me', curriculumId);
                    dispatch({ type: 'DeleteResult', curriculumId });
                },
                onError: () => {
                    dispatch({ type: 'UpdateError', curriculumId });
                },
                progressMessage: intl.formatMessage(
                    {
                        description: 'Progress message for an async operation.',
                        defaultMessage: 'Deleting activity {ACTIVITY_TITLE}.',
                    },
                    { ACTIVITY_TITLE: title }
                ),
                successMessage: intl.formatMessage(
                    {
                        description: 'Success message for an async operation.',
                        defaultMessage: 'Successfully deleted activity {ACTIVITY_TITLE}.',
                    },
                    { ACTIVITY_TITLE: title }
                ),
                errorMessage: intl.formatMessage(
                    {
                        description: 'Error message for an async operation.',
                        defaultMessage: 'An error ocurred while deleting activity {ACTIVITY_TITLE}.',
                    },
                    { ACTIVITY_TITLE: title }
                ),
            });
        },
        [intl, runner]
    );

    // Downloads a curriculum.
    const downloadCurriculum = useCallback(
        (curriculum: Curriculum) => {
            const { title } = curriculum;
            runner.run({
                task: async () => {
                    // TODO: const zip = await client!.downloadUserCurriculum('me', curriculumId);
                    const zip = { blobBody: Promise.resolve(new Blob()) };
                    if (zip.blobBody !== undefined) {
                        downloadBlob(await zip.blobBody, `${title}.zip`);
                    }
                },
                progressMessage: intl.formatMessage(
                    {
                        description: 'Progress message for an async operation.',
                        defaultMessage: 'Downloading activity {ACTIVITY_TITLE}.',
                    },
                    { ACTIVITY_TITLE: title }
                ),
                errorMessage: intl.formatMessage(
                    {
                        description: 'Error message for an async operation.',
                        defaultMessage: 'An error ocurred while downloading activity {ACTIVITY_TITLE}.',
                    },
                    { ACTIVITY_TITLE: title }
                ),
            });
        },
        [intl, runner]
    );

    // Store action reducer - updates store state and kicks off async actions.
    const reducer = useCallback(
        (store: CurriculumStore, action: CurriculumAction) =>
            produce(store, (draft) => {
                traceEvent('curriculum-context.action', { type: action.type, state: draft.state });
                switch (action.type) {
                    case 'Load':
                        if (draft.state === 'NotLoaded') {
                            listCurricula();
                            draft.state = 'Loading';
                        }
                        break;
                    case 'Reload':
                        if (draft.state !== 'Loading' && draft.state !== 'NotLoaded') {
                            listCurricula();
                            draft.state = 'Loading';
                        }
                        break;
                    case 'LoadResult':
                        if (action.items !== undefined) {
                            draft.items = action.items;
                            draft.state = 'Loaded';
                        } else {
                            draft.items = [];
                            draft.state = 'NotLoaded';
                        }
                        break;
                    case 'LoadError':
                        draft.state = 'NotLoaded';
                        break;
                    case 'Create': {
                        const item = {
                            ...action.curriculum,
                            curriculumId: crypto.randomUUID(),
                        } as Curriculum;

                        createCurriculum(item);
                        draft.items.push(item);
                        break;
                    }
                    case 'CreateError': {
                        const index = draft.items.findIndex((o) => o.curriculumId === action.curriculumId);
                        if (index >= 0) {
                            draft.items.splice(index, 1);
                        }
                        break;
                    }
                    case 'Update': {
                        const item = draft.items.find((o) => o.curriculumId === action.curriculum.curriculumId);
                        if (item) {
                            updateCurriculum(action.curriculum, item);
                            item.state = 'updating';
                        }
                        break;
                    }
                    case 'UpdateError': {
                        const item = draft.items.find((o) => o.curriculumId === action.curriculumId);
                        if (item) {
                            item.state = action.previous?.state ?? 'draft';
                        }
                        break;
                    }
                    case 'Publish': {
                        const item = draft.items.find((o) => o.curriculumId === action.curriculumId);
                        if (item) {
                            publishCurriculum(item);
                            item.state = 'publishing';
                        }
                        break;
                    }
                    case 'Unpublish': {
                        const item = draft.items.find((o) => o.curriculumId === action.curriculumId);
                        if (item) {
                            unpublishCurriculum(item);
                            item.state = 'unpublishing';
                        }
                        break;
                    }
                    case 'UpdateResult': {
                        const index = draft.items.findIndex((o) => o.curriculumId === action.curriculum.curriculumId);
                        if (index >= 0) {
                            draft.items[index] = action.curriculum;
                        }
                        break;
                    }
                    case 'Delete': {
                        const item = draft.items.find((o) => o.curriculumId === action.curriculumId);
                        if (item) {
                            deleteCurriculum(item);
                            item.state = 'deleting';
                        }
                        break;
                    }
                    case 'DeleteResult': {
                        const index = draft.items.findIndex((o) => o.curriculumId === action.curriculumId);
                        if (index >= 0) {
                            draft.items.splice(index, 1);
                        }
                        break;
                    }
                    case 'Download': {
                        const item = store.items.find((o) => o.curriculumId === action.curriculumId);
                        if (item) {
                            downloadCurriculum(item);
                        }
                        break;
                    }
                    default:
                        assert.unreachable(action, 'action');
                }
            }),
        [
            listCurricula,
            createCurriculum,
            updateCurriculum,
            publishCurriculum,
            unpublishCurriculum,
            deleteCurriculum,
            downloadCurriculum,
        ]
    );

    const [state, dispatch] = useReducer(reducer, { state: 'NotLoaded', items: [] });
    return <CurriculumContext.Provider value={[state, dispatch]}>{props.children}</CurriculumContext.Provider>;
});

// Use user curriculum context.
export const useCurricula = () => {
    const [store, dispatch] = useContext(CurriculumContext);
    useEffect(() => dispatch({ type: 'Load' }), [dispatch]);
    return [store, dispatch] as [typeof store, typeof dispatch];
};

// Selector to get a curriculum by ID.
export const getCurriculum = createSelector(
    (store: CurriculumStore) => store.items,
    (_: unknown, curriculumId: string) => curriculumId,
    (items, curriculumId) => items.find((o) => o.curriculumId === curriculumId)
);

// Selector to get whether curriculum store is currently loading.
export const getIsLoadingCurricula = createSelector(
    (store: CurriculumStore) => store.state,
    (state) => state !== 'Loaded'
);
