import { Field, Input } from '@fluentui/react-components';
import React, { useCallback, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useAppStyles } from '../../styles';
import { IntlProps } from '../common';

// Confirmation field properties.
export type ConfirmationFieldProps = IntlProps<{
    initialValue: string;
    confirmationText: string;
    onChange: (confirmed: boolean) => void;
}>;

// Input field that requires the user to type in a confirmation text.
export default injectIntl((props: ConfirmationFieldProps) => {
    const { intl, confirmationText, onChange, initialValue } = props;
    const [value, setValue] = useState(initialValue);
    const styles = useAppStyles();

    const onInputChange = useCallback(
        (_e: unknown, data: { value: string }) => {
            setValue(data.value);
            onChange(data.value === confirmationText);
        },
        [onChange, confirmationText]
    );

    return (
        <Field
            className={styles.dialogField}
            required
            label={
                <span className={styles.noUserSelect}>
                    <FormattedMessage
                        description="Confirmation text input instructions"
                        defaultMessage="Enter {TEXT} to confirm the operation"
                        values={{ TEXT: <span className={styles.textSemibold}>&nbsp;{confirmationText}&nbsp;</span> }}
                    />
                </span>
            }
        >
            <Input
                value={value}
                onChange={onInputChange}
                maxLength={confirmationText.length}
                onPaste={(e) => e.preventDefault()}
                placeholder={intl.formatMessage({
                    description: 'Placeholder text inside the confirmation text input.',
                    defaultMessage: 'Type required text here',
                })}
            />
        </Field>
    );
});
