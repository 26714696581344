import { Button, Divider, Menu, MenuList, MenuPopover, MenuTrigger, mergeClasses } from '@fluentui/react-components';
import {
    ArrowDownloadRegular,
    CloudRegular,
    DeleteRegular,
    MoreVerticalRegular,
    RenameRegular,
    ShareRegular,
} from '@fluentui/react-icons';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Project } from '../../models';
import { useAppStyles } from '../../styles';
import { timeSince } from '../../utilities/time';
import { Indicator, IntlProps, ProjectTypeLabel, TrackContext, TrackedLink, TrackedMenuItem } from '../common';
import { useOpenProjectUrl } from '../../data';

// User project card properties.
export type ProjectCardProps = IntlProps<{
    project: Project;
    onPublish: () => void;
    onShowPublished: () => void;
    onDelete: () => void;
    onUpdate: () => void;
    onDownload: () => void;
    onShare: () => void;
}>;

// User project card.
export default injectIntl((props: ProjectCardProps) => {
    const { intl, project, onPublish, onShowPublished, onDelete, onUpdate, onDownload, onShare } = props;
    const { projectId, state, type, title, description, lastUpdated, lastPublished, sharedLinkId } = project;
    const openProjectUrl = useOpenProjectUrl(projectId).toString();
    const styles = useAppStyles();

    return (
        <TrackContext.Provider value={{ container: 'project-card', props: { projectId } }}>
            <div className={mergeClasses(styles.card, styles.cardThreeColumns)}>
                <div className={styles.cardContent}>
                    <div className={styles.flexRowCentered}>
                        <ProjectTypeLabel className={mergeClasses(styles.cardCategory, styles.flexGrow1)} type={type} />
                        <Menu>
                            <MenuTrigger disableButtonEnhancement>
                                <Button
                                    icon={<MoreVerticalRegular />}
                                    appearance="subtle"
                                    title={intl.formatMessage({
                                        description: 'Card menu more icon aria label',
                                        defaultMessage: 'More options',
                                    })}
                                />
                            </MenuTrigger>
                            <MenuPopover>
                                <MenuList>
                                    {type === 'html' && (
                                        <TrackedMenuItem
                                            trackAction="publish"
                                            disabled={state !== 'Created'}
                                            icon={<CloudRegular />}
                                            onClick={onPublish}
                                        >
                                            <FormattedMessage
                                                description="Project card 'Publish' menu option"
                                                defaultMessage="Publish"
                                            />
                                        </TrackedMenuItem>
                                    )}
                                    <Divider />
                                    <TrackedMenuItem
                                        trackAction="rename"
                                        disabled={state !== 'Created'}
                                        icon={<RenameRegular />}
                                        onClick={onUpdate}
                                    >
                                        <FormattedMessage
                                            description="Project card 'Rename' menu option"
                                            defaultMessage="Rename..."
                                        />
                                    </TrackedMenuItem>
                                    <TrackedMenuItem
                                        trackAction="share"
                                        disabled={state !== 'Created'}
                                        icon={<ShareRegular />}
                                        onClick={onShare}
                                    >
                                        <FormattedMessage
                                            description="Project card 'Share' menu option"
                                            defaultMessage="Share..."
                                        />
                                    </TrackedMenuItem>
                                    <TrackedMenuItem
                                        trackAction="download"
                                        disabled={state !== 'Created'}
                                        icon={<ArrowDownloadRegular />}
                                        onClick={onDownload}
                                    >
                                        <FormattedMessage
                                            description="Project card 'Download' menu option"
                                            defaultMessage="Download"
                                        />
                                    </TrackedMenuItem>
                                    <Divider />
                                    <TrackedMenuItem
                                        trackAction="delete"
                                        disabled={state !== 'Created'}
                                        icon={<DeleteRegular />}
                                        onClick={onDelete}
                                    >
                                        <FormattedMessage
                                            description="Project card 'Delete' menu option"
                                            defaultMessage="Delete"
                                        />
                                    </TrackedMenuItem>
                                </MenuList>
                            </MenuPopover>
                        </Menu>
                    </div>
                    <TrackedLink
                        trackAction="open"
                        className={mergeClasses(styles.cardTitle, styles.linkButton)}
                        href={openProjectUrl}
                    >
                        {title}
                    </TrackedLink>
                    <div className={styles.cardDescription}>{description}</div>
                    <div className={styles.cardFooter}>
                        {state === 'Creating' && (
                            <Indicator type="spinner">
                                <FormattedMessage
                                    description="Message indicating a project is being created."
                                    defaultMessage="Creating..."
                                />
                            </Indicator>
                        )}
                        {state === 'Publishing' && (
                            <Indicator type="spinner">
                                <FormattedMessage
                                    description="Message indicating a project is being published."
                                    defaultMessage="Publishing..."
                                />
                            </Indicator>
                        )}
                        {state === 'Updating' && (
                            <Indicator type="spinner">
                                <FormattedMessage
                                    description="Message indicating a project is being updated."
                                    defaultMessage="Updating..."
                                />
                            </Indicator>
                        )}
                        {state === 'Deleting' && (
                            <Indicator type="spinner">
                                <FormattedMessage
                                    description="Message indicating a project is being deleted."
                                    defaultMessage="Deleting..."
                                />
                            </Indicator>
                        )}
                        {state === 'Created' && lastUpdated !== undefined && (
                            <FormattedMessage
                                description="Last time when a project was last updated. TIME_SINCE: '15 minutes ago'."
                                defaultMessage="Last edited {TIME_SINCE}"
                                values={{ TIME_SINCE: timeSince(lastUpdated.getTime(), intl) }}
                            />
                        )}
                        <div className={styles.flexGrow1} />
                        {type === 'html' && lastPublished && (
                            <TrackedLink
                                trackAction="published-link"
                                appearance="subtle"
                                onClick={onShowPublished}
                                title={intl.formatMessage({
                                    description: 'Description of a link opening project publishing information.',
                                    defaultMessage: 'View publishing information',
                                })}
                            >
                                <div className={mergeClasses(styles.cardPill, styles.textSmallCaps)}>
                                    <FormattedMessage
                                        description="Text on project card indicating a project is published."
                                        defaultMessage="published"
                                    />
                                </div>
                            </TrackedLink>
                        )}
                        {sharedLinkId !== undefined && (
                            <TrackedLink
                                trackAction="shared-link"
                                appearance="subtle"
                                onClick={onShare}
                                title={intl.formatMessage({
                                    description: 'Description of a link opening project sharing information.',
                                    defaultMessage: 'View sharing information',
                                })}
                            >
                                <div className={mergeClasses(styles.cardPill, styles.textSmallCaps)}>
                                    <FormattedMessage
                                        description="Text on project card indicating a project is shared."
                                        defaultMessage="shared"
                                    />
                                </div>
                            </TrackedLink>
                        )}
                    </div>
                </div>
            </div>
        </TrackContext.Provider>
    );
});
