import { VSCodeUrl, VsCodeEduClientExt, WorkspaceScheme, isNonEmptyString, validate } from '@microsoft/vscodeedu-api';
import { trackInfo } from './diagnostics';
import { apiEnvironment } from '../data';
import { Redirecting } from '../data/vsce-identity-provider';

/*
 * Check an incoming URL, if it's a shared link, handle it and redirect to vscode.dev.
 * if it's not a shared link, ignore. If it's a shared link and there is an error with the link, throw.
 * Note that we should be forcing a user login earlier in the flow
 */
export async function handleSharedLink(sharedLinkId: string, anonymousClient: VsCodeEduClientExt): Promise<void> {
    trackInfo(`Handling shared link for id: ${sharedLinkId}`);
    const shareLink = await anonymousClient.getSharedLink(sharedLinkId);

    const shareLinkUserId = shareLink.targetId.split(':')[0];
    const shareLinkProjectId = shareLink.targetId.split(':')[1];
    if (!isNonEmptyString(shareLinkProjectId) || !isNonEmptyString(shareLinkUserId)) {
        throw new Error(`Invalid shared link id ${sharedLinkId}`);
    }

    // Check that the user / project combo is valid, if not, it will throw
    await anonymousClient.getUserProject(shareLinkUserId, shareLinkProjectId, {
        requestOptions: {
            customHeaders: {
                'x-shared-link-id': sharedLinkId,
            },
        },
    });

    // Pass the shared link id to create the URL the extension will open
    const vscodeDevLink = VSCodeUrl.forSharedProject(apiEnvironment, sharedLinkId, {
        // Shared links only work with the new FSP so just set it here without checking the feature flag
        workspaceScheme: WorkspaceScheme.Workspace,
    });

    const vscodeDevLinkString = vscodeDevLink.toString();
    trackInfo(`Redirecting from shared link to vscode.dev with url: ${vscodeDevLinkString}`);

    // Redirect to vscode.dev
    window.location.replace(vscodeDevLinkString);
    throw new Redirecting('Redirecting from shared link');
}

/*
 * If pathName is a shared link, return the shared link id. If not, return undefined.
 */
export function getSharedLinkId(pathName: string): string | undefined {
    // If the pathName starts with '/beta' case insenstive, remove it
    pathName = pathName.replace(/^\/BETA/i, '');

    const slashRemoved = pathName.slice(1);
    return validate.sharedLinkId(slashRemoved, 'pathName') === undefined ? slashRemoved : undefined;
}
