import {
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogProps,
    DialogSurface,
    Field,
    Input,
    Textarea,
} from '@fluentui/react-components';
import React, { useCallback, useContext, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { CurriculumContext } from '../../data/curriculum-context';
import { Curriculum, MaxDescriptionLength, MaxTitleLength } from '../../models';
import { useAppStyles } from '../../styles';
import { IntlProps, TrackContext } from '../common';
import { CancelButton, ConfirmButton, DialogHeader } from '../dialogs';

// Create activity dialog.
export default injectIntl((props: IntlProps<Partial<DialogProps>>) => {
    const { intl } = props;
    const appStyles = useAppStyles();

    const [, dispatch] = useContext(CurriculumContext);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');

    const onConfirm = useCallback(() => {
        const desc = description.trim();
        dispatch({
            type: 'Create',
            curriculum: {
                title: title.trim(),
                description: desc.length > 0 ? desc : undefined,
            } as Curriculum,
        });
    }, [title, description, dispatch]);

    return (
        <TrackContext.Provider value={{ container: 'create-activity-dialog' }}>
            <Dialog {...props}>
                <DialogSurface className={appStyles.dialogNarrow}>
                    <DialogBody>
                        <DialogHeader
                            title={intl.formatMessage({
                                description: 'Create activity dialog title.',
                                defaultMessage: 'Create an activity',
                            })}
                        />
                        <DialogContent>
                            <Field
                                className={appStyles.dialogField}
                                required
                                label={intl.formatMessage({
                                    description: 'Label for the activity title text box.',
                                    defaultMessage: 'Activity title',
                                })}
                            >
                                <Input
                                    value={title}
                                    onChange={(_, { value }) => setTitle(value)}
                                    maxLength={MaxTitleLength}
                                    placeholder={intl.formatMessage({
                                        description: 'Placeholder text inside the activity title text box.',
                                        defaultMessage: 'Name your activity',
                                    })}
                                />
                            </Field>
                            <Field
                                className={appStyles.dialogField}
                                label={intl.formatMessage({
                                    description: 'Label for the activity description text box.',
                                    defaultMessage: 'Description (optional)',
                                })}
                            >
                                <Textarea
                                    value={description}
                                    onChange={(_, { value }) => setDescription(value)}
                                    maxLength={MaxDescriptionLength}
                                    placeholder={intl.formatMessage({
                                        description: 'Placeholder text inside the activity description text box.',
                                        defaultMessage: 'Tell us about your activity...',
                                    })}
                                />
                            </Field>
                        </DialogContent>
                        <DialogActions>
                            <CancelButton />
                            <ConfirmButton appearance="primary" disabled={!title.trim()} onClick={onConfirm}>
                                <FormattedMessage
                                    description="Create new activity dialog confirm button."
                                    defaultMessage="Create activity"
                                />
                            </ConfirmButton>
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        </TrackContext.Provider>
    );
});
