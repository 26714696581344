import { TableCellLayout } from '@fluentui/react-components';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Curriculum } from '../../models';
import { Indicator } from '../common';

// curriculum status cell properties.
export type StatusCellProps = Readonly<{
    state: Curriculum['state'];
}>;

// Last Published data grid cell contents.
export default (props: StatusCellProps) => {
    const { state } = props;
    return (
        <TableCellLayout>
            {state === 'creating' && (
                <Indicator type="spinner">
                    <FormattedMessage
                        description="Text in Status column of activities data grid."
                        defaultMessage="Creating..."
                    />
                </Indicator>
            )}
            {state === 'draft' && (
                <FormattedMessage description="Text in Status column of activities data grid." defaultMessage="Draft" />
            )}
            {state === 'updating' && (
                <Indicator type="spinner">
                    <FormattedMessage
                        description="Text in Status column of activities data grid."
                        defaultMessage="Updating..."
                    />
                </Indicator>
            )}
            {state === 'publishing' && (
                <Indicator type="spinner">
                    <FormattedMessage
                        description="Text in Status column of activities data grid."
                        defaultMessage="Publishing..."
                    />
                </Indicator>
            )}
            {state === 'published' && (
                <FormattedMessage
                    description="Text in Status column of activities data grid."
                    defaultMessage="Published"
                />
            )}
            {state === 'unpublishing' && (
                <Indicator type="spinner">
                    <FormattedMessage
                        description="Text in Status column of activities data grid."
                        defaultMessage="Unpublishing..."
                    />
                </Indicator>
            )}
        </TableCellLayout>
    );
};
