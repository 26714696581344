import {
    createTableColumn,
    DataGrid,
    DataGridBody,
    DataGridCell,
    DataGridHeader,
    DataGridHeaderCell,
    DataGridRow,
    TableColumnDefinition,
    TableColumnSizingOptions,
} from '@fluentui/react-components';
import { compareDates, compareLocale } from '@microsoft/vscodeedu-api';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Curriculum } from '../../models';
import { useAppStyles } from '../../styles';
import { TrackContext } from '../common';
import EmptyState from './empty-state';
import LastPublishedCell from './last-published-cell';
import NameCell from './name-cell';
import PlatformCell from './platform-cell';
import StatusCell from './status-cell';
import TypeCell from './type-cell';

// Curriculum list properties.
export type PublishedProjectListProps = Readonly<{
    isLoading: boolean;
    curricula: ReadonlyArray<Curriculum>;
    onCreate: () => void;
    onPublish: (curriculumId: string) => void;
    onUnpublish: (curriculumId: string) => void;
    onUpdate: (curriculum: Curriculum) => void;
    onDelete: (curriculumId: string) => void;
    onDownload: (curriculumId: string) => void;
}>;

const columnSizingOptions: TableColumnSizingOptions = {
    name: {
        idealWidth: 500,
        defaultWidth: 500,
    },
    type: {
        idealWidth: 200,
        defaultWidth: 200,
    },
    language: {
        idealWidth: 200,
        defaultWidth: 200,
    },
    status: {
        idealWidth: 300,
        defaultWidth: 300,
    },
    lastPublished: {
        idealWidth: 300,
        defaultWidth: 300,
    },
};

// Curriculum data grid.
export default (props: PublishedProjectListProps) => {
    const { curricula, onCreate } = props;
    const appStyles = useAppStyles();

    const columns = useMemo<TableColumnDefinition<Curriculum>[]>(
        () => [
            createTableColumn({
                columnId: 'name',
                compare: (a, b) => compareLocale(a.title, b.title),
                renderHeaderCell: () => (
                    <FormattedMessage description="Column title for activity data grid." defaultMessage="Title" />
                ),
                renderCell: (item) => <NameCell {...item} />,
            }),
            createTableColumn({
                columnId: 'type',
                compare: (a, b) => compareLocale(a.type, b.type),
                renderHeaderCell: () => (
                    <FormattedMessage description="Column title for activity data grid." defaultMessage="Type" />
                ),
                renderCell: (item) => <TypeCell {...item} />,
            }),
            createTableColumn({
                columnId: 'language',
                compare: (a, b) => compareLocale(a.platform, b.platform),
                renderHeaderCell: () => (
                    <FormattedMessage description="Column title for activity data grid." defaultMessage="Language" />
                ),
                renderCell: (item) => <PlatformCell {...item} />,
            }),
            createTableColumn({
                columnId: 'status',
                compare: (a, b) => compareLocale(a.state, b.state),
                renderHeaderCell: () => (
                    <FormattedMessage description="Column title for activity data grid." defaultMessage="Status" />
                ),
                renderCell: (item) => <StatusCell {...item} />,
            }),
            createTableColumn({
                columnId: 'lastPublished',
                compare: (a, b) => compareDates(a.lastPublished, b.lastPublished),
                renderHeaderCell: () => (
                    <FormattedMessage
                        description="Column title for activity data grid."
                        defaultMessage="Last published"
                    />
                ),
                renderCell: (item) => <LastPublishedCell {...item} />,
            }),
        ],
        []
    );

    return (
        <TrackContext.Provider value={{ container: 'curriculum-list' }}>
            {curricula.length === 0 && <EmptyState onCreate={onCreate} />}
            {curricula.length > 0 && (
                <DataGrid
                    items={curricula as Curriculum[]}
                    getRowId={(item) => item.curriculumId}
                    columns={columns}
                    columnSizingOptions={columnSizingOptions}
                    resizableColumns
                    sortable
                >
                    <DataGridHeader>
                        <DataGridRow>
                            {({ renderHeaderCell }) => <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>}
                        </DataGridRow>
                    </DataGridHeader>
                    <DataGridBody<Curriculum>>
                        {({ item, rowId }) => (
                            <DataGridRow<Curriculum> key={rowId}>
                                {({ renderCell }) => (
                                    <DataGridCell className={appStyles.flexColumn}>
                                        <TrackContext.Provider
                                            value={{
                                                container: 'activity-list-row',
                                                props: { curriculumId: item.curriculumId },
                                            }}
                                        >
                                            {renderCell(item)}
                                        </TrackContext.Provider>
                                    </DataGridCell>
                                )}
                            </DataGridRow>
                        )}
                    </DataGridBody>
                </DataGrid>
            )}
        </TrackContext.Provider>
    );
};
