import { Theme, webDarkTheme, webLightTheme } from '@fluentui/react-components';

// Default app theme.
export const darkTheme: Theme = {
    ...webDarkTheme,

    colorNeutralForeground1: '#F8F8F8', // primary strong white (titles etc)
    colorNeutralForeground2: '#CCCCCC', // secondary off-white (regular text)

    colorNeutralBackground1: '#1E1E1E', // overall site background
    colorNeutralBackground2: '#252526', // background for cards and dialogs
    colorNeutralBackground3: '#3C3C3C', // progress bar

    colorNeutralBackgroundStatic: '#000000',
    colorBrandBackgroundHover: '#0065A9',
    colorBrandBackground2: '#6068DD',
    colorPaletteBlueForeground2: '#4F95F7', // progress bar
    colorPaletteYellowBackground1: '#FFC541',
};

export const lightTheme: Theme = {
    ...webLightTheme,

    colorNeutralForeground1: '#1E1E1E',
    colorNeutralForeground2: '#252526',

    colorNeutralBackground1: '#FFFFFF',
    colorNeutralBackground2: '#FAFAFA',
    colorNeutralBackground3: '#F5F5F5',

    colorNeutralStroke1: '#666666',
    colorNeutralBackgroundStatic: '#FFFFFF',
    colorBrandBackgroundHover: '#0065A9',
    colorNeutralBackground1Hover: '#F5F5F5',
    colorBrandBackground2: '#6068DD',
    colorPaletteBlueForeground2: '#4F95F7',
    colorPaletteYellowBackground1: '#FFC541',
};

export const highContrastLightTheme: Theme = {
    ...lightTheme,

    colorNeutralBackground1: '#FFFFFF',
    colorNeutralBackground2: '#FFFFFF',
    colorNeutralBackground3: '#FFFFFF',

    colorNeutralForeground1: '#000000',
    colorNeutralForeground2: '#000000',
    colorNeutralForeground3: '#000000',

    colorNeutralStroke1: '#0f4a85',
    colorNeutralStroke2: '#0f4a85',
    colorNeutralStroke3: '#0f4a85',

    colorStrokeFocus2: '#296CB7',
    colorNeutralStrokeAccessibleSelected: '#296CB7',
};

export const highContrastDarkTheme: Theme = {
    ...darkTheme,

    colorNeutralBackground1: '#000000',
    colorNeutralBackground2: '#000000',
    colorNeutralBackground3: '#000000',

    colorNeutralBackground1Hover: '#202020',

    colorNeutralForeground1: '#FFFFFF',
    colorNeutralForeground2: '#FFFFFF',
    colorNeutralForeground3: '#FFFFFF',

    colorNeutralStroke1: '#82C2DC', // Outline of cards
    colorNeutralStroke2: '#82C2DC',
    colorNeutralStroke3: '#82C2DC',

    colorNeutralStrokeAccessibleSelected: '#f38518', // Tab-slected items
};
