import { shorthands, tokens } from '@fluentui/react-components';
import { defineStyles } from './define-styles';
import { MOBILE_AND_BELOW, TABLET_AND_BELOW } from './responsive';

// Main content styles.
export const contentStyles = defineStyles({
    // Header
    header: {
        height: '3.5rem',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingBottom: '0.5rem',
        [TABLET_AND_BELOW]: {
            justifyContent: 'space-between',
            '& > div > button': {
                minWidth: '2.5rem',
                minHeight: '2.5rem',
            },
        },
    },
    // Top-level container for a page.
    pageContainer: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: 'calc(100vh - 4rem)',
    },
    // Page content below header inside page container.
    pageContent: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        backgroundColor: tokens.colorNeutralBackground1,
    },
    // Empty state layout for various pages.
    emptyState: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        rowGap: tokens.spacingVerticalXL,
        alignItems: 'center',
        fontSize: tokens.fontSizeBase500,
        lineHeight: tokens.lineHeightBase500,
        paddingTop: tokens.spacingVerticalXXXL,
    },
    // Image displayed on empty state.
    emptyStateImage: {
        width: '10rem',
        height: '10rem',
    },
    // Content section container.
    section: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: tokens.spacingVerticalXXXL,
        paddingBottom: tokens.spacingVerticalXXL,
    },
    // Content section title within the container.
    sectionTitle: {
        fontSize: tokens.fontSizeHero800,
        lineHeight: tokens.lineHeightHero800,
        fontWeight: tokens.fontWeightMedium,
        letterSpacing: '-0.1rem',
        [MOBILE_AND_BELOW]: {
            fontSize: tokens.fontSizeHero700,
            lineHeight: tokens.lineHeightHero700,
        },
    },
    // Content section main content within the container.
    sectionContent: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: tokens.spacingVerticalXXL,
    },
    // Regular text color.
    textDarker: {
        color: tokens.colorNeutralForeground2,
    },
    // Small text size.
    textSmall: {
        fontSize: tokens.fontSizeBase200,
        lineHeight: tokens.lineHeightBase200,
    },
    // Show text in small caps.
    textSmallCaps: {
        fontVariant: 'small-caps',
    },
    // Show text in all caps.
    textSpacedCaps: {
        textTransform: 'uppercase',
        letterSpacing: '0.1rem',
    },
    // Light weight text variant.
    textLight: {
        fontWeight: '300',
    },
    // Bolder text variant.
    textSemibold: {
        fontWeight: tokens.fontWeightSemibold,
    },
    // Bold text variant.
    textBold: {
        fontWeight: tokens.fontWeightBold,
    },
    // Prevent user from selecting text.
    noUserSelect: {
        userSelect: 'none',
    },
    // Show cursor pointer.
    cursorPointer: {
        cursor: 'pointer',
    },
    // NavLink style.
    navLink: {
        color: 'unset !important',
        ...shorthands.textDecoration('none'),
        '&:hover': {
            color: `${tokens.colorNeutralForeground2LinkHover} !important`,
            ...shorthands.textDecoration('underline'),
        },
    },
});
