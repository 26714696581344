import { FluentProvider } from '@fluentui/react-components';
import { isNonEmptyString } from '@microsoft/vscodeedu-api';
import React, { ReactElement } from 'react';
import { createRoot } from 'react-dom/client';
import { IntlProvider } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';
import {
    ConfigContextProvider,
    CourseContextProvider,
    ProjectContextProvider,
    ProjectTemplateContextProvider,
    UserContextProvider,
    UserProgressContextProvider,
    anonymousClient,
    preloadConfig,
    routerBasename,
} from './data';
import { CurriculumContextProvider } from './data/curriculum-context';
import { PostContextProvider } from './data/post-context';
import { StandardContextProvider } from './data/standard-context';
import { ThemeContextProvider } from './data/theme-context';
import { Redirecting, VSCodeEduIdentityProvider } from './data/vsce-identity-provider';
import './index.css';
import SignIn from './pages/sign-in';
import Routes from './routes';
import { darkTheme, traceFormFactor } from './styles';
import { initializeAppInsights, trackError, trackVerbose } from './utilities/diagnostics';
import { createReport, timeAsync, timeSync } from './utilities/performance';
import { getSharedLinkId, handleSharedLink } from './utilities/sharedLinks';

// Load config as soon as practically possible to avoid race conditions.
const configTask = preloadConfig();

const renderContent = async (element: ReactElement) => {
    await configTask;
    const container = document.getElementById('root') as HTMLElement;
    const root = createRoot(container);
    root.render(element);
};

const initialize = async () => {
    // Initialize Application Insights telemetry.
    timeSync('init-app-insights', initializeAppInsights);

    let messages: { [index: string]: string };
    const locale = 'en';
    switch (locale) {
        case 'en':
            messages = require('./lang/en.json');
            break;
    }

    try {
        // Handle Entra authentication redirect.
        const authProvider = new VSCodeEduIdentityProvider();
        await authProvider.handleEntraRedirect();

        // Handle vscode.dev sign-in requests.
        if ((await authProvider.handleVSCodeSilentSignIn()) || authProvider.requiresSharedLinkSignIn()) {
            // Get a possible shared link redirect for signing, will be undefined if not a shared link.
            const shareLinkRedirect = getSharedLinkId(window.location.pathname);

            await renderContent(
                <FluentProvider theme={darkTheme}>
                    <IntlProvider messages={messages} locale="en" defaultLocale="en">
                        <ConfigContextProvider>
                            <SignIn redirectTo={shareLinkRedirect} />
                        </ConfigContextProvider>
                    </IntlProvider>
                </FluentProvider>
            );
            return;
        }
    } catch (e) {
        if (e instanceof Redirecting) {
            // Skip further initialization in case of redirect.
            return;
        } else {
            // There was an error during sign-in.
            // We will show it when rendering /redirect or /signin routes.
            trackError(e, 'Error during sign-in redirect flow.');
        }
    }

    try {
        // Handle an incoming shared link.
        const shareLinkId = getSharedLinkId(window.location.pathname);
        if (isNonEmptyString(shareLinkId)) {
            await handleSharedLink(shareLinkId, anonymousClient);
        }
    } catch (e) {
        if (e instanceof Redirecting) {
            // Skip further initialization in case of redirect.
            return;
        } else {
            trackError(e, 'Error during handling of shared link.');
        }
    }

    await renderContent(
        <IntlProvider messages={messages} locale="en" defaultLocale="en">
            <BrowserRouter basename={routerBasename}>
                <ConfigContextProvider>
                    <PostContextProvider>
                        <StandardContextProvider>
                            <UserContextProvider>
                                <ThemeContextProvider>
                                    <CourseContextProvider>
                                        <UserProgressContextProvider>
                                            <ProjectTemplateContextProvider>
                                                <ProjectContextProvider>
                                                    <CurriculumContextProvider>
                                                        <Routes />
                                                    </CurriculumContextProvider>
                                                </ProjectContextProvider>
                                            </ProjectTemplateContextProvider>
                                        </UserProgressContextProvider>
                                    </CourseContextProvider>
                                </ThemeContextProvider>
                            </UserContextProvider>
                        </StandardContextProvider>
                    </PostContextProvider>
                </ConfigContextProvider>
            </BrowserRouter>
        </IntlProvider>
    );

    traceFormFactor();
};

(async () => {
    await timeAsync('init', initialize);
    trackVerbose(createReport());
})();
