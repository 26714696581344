import { mergeClasses } from '@fluentui/react-components';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CreateImage } from '../../icons/status';
import { useAppStyles } from '../../styles';
import { TrackedButton } from '../common';

// Custom course list empty state properties.
export type EmptyStateProps = Readonly<{
    onCreate: () => void;
}>;

// Custom course list empty state.
export default (props: EmptyStateProps) => {
    const { onCreate } = props;
    const styles = useAppStyles();

    return (
        <div className={styles.emptyState}>
            <CreateImage className={styles.emptyStateImage} />
            <FormattedMessage
                description="Empty activity list message"
                defaultMessage="It's lonely in here... create an activity to get started."
            />
            <TrackedButton
                trackAction="new-activity"
                className={mergeClasses(styles.button, styles.buttonPrimary)}
                onClick={onCreate}
            >
                <FormattedMessage
                    description="Empty activity list new activity button"
                    defaultMessage="Create new activity"
                />
            </TrackedButton>
        </div>
    );
};
