import { FluentProvider, Theme } from '@fluentui/react-components';
import { produce } from 'immer';
import React, { createContext, Dispatch, ReactNode, useCallback, useContext, useReducer } from 'react';
import { darkTheme, highContrastDarkTheme, highContrastLightTheme, lightTheme } from '../styles';
import { StoreState } from './store-state';
import { ProfileTheme } from '@microsoft/vscodeedu-common';

export interface ThemeStore {
    state: StoreState;
    theme: Theme;
    profileTheme: ProfileTheme;
    isDarkTheme: boolean;
}

export type ThemeAction = { type: 'Set'; theme: ProfileTheme };

const ThemeContext = createContext<[state: ThemeStore, reducer: Dispatch<ThemeAction>]>(undefined!);
const DEFAULT_THEME = { theme: darkTheme, profileName: ProfileTheme.Dark };

// Session storage
const prefix = 'VSCodeEduThemeProvider:';
const themeKey = `${prefix}theme`;

export const ThemeContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const reducer = useCallback(
        (store: ThemeStore, action: ThemeAction) =>
            produce(store, (draft) => {
                switch (action.type) {
                    case 'Set':
                        draft.profileTheme = action.theme;
                        draft.theme = getThemeFromProfileName(action.theme);
                        draft.isDarkTheme = isDarkTheme(draft.theme);
                        localStorage.setItem(themeKey, action.theme);
                        break;
                }
            }),
        []
    );

    const profileTheme = localStorage.getItem(themeKey);
    const parsedTheme = convertEnumStringValueToProfileThemeEnum(profileTheme);
    const theme = getThemeFromProfileName(parsedTheme);

    const [state, dispatch] = useReducer(reducer, {
        state: 'Loaded',
        theme: theme,
        profileTheme: parsedTheme,
        isDarkTheme: isDarkTheme(theme),
    });
    
    return (
        <ThemeContext.Provider value={[state, dispatch]}>
            <FluentProvider theme={state.theme}>{children}</FluentProvider>
        </ThemeContext.Provider>
    );
};

const isDarkTheme = (profileTheme: Theme): boolean => {
    return profileTheme === darkTheme || profileTheme === highContrastDarkTheme;
};

const getThemeFromProfileName = (profileTheme: ProfileTheme): Theme => {
    switch (profileTheme) {
        case ProfileTheme.Dark:
            return darkTheme;
        case ProfileTheme.HighContrastDark:
            return highContrastDarkTheme;
        case ProfileTheme.HighContrastLight:
            return highContrastLightTheme;
        case ProfileTheme.Light:
            return lightTheme;
        default:
            return DEFAULT_THEME.theme;
    }
};

export const useTheme = () => {
    return useContext(ThemeContext);
};

export function convertEnumStringValueToProfileThemeEnum(value: string | null): ProfileTheme {
    // parse the string value to the ProfileTheme enum
    // if the value is not a valid ProfileTheme enum value, return the default value

    if (Object.values(ProfileTheme).includes(value as ProfileTheme)) {
        return value as ProfileTheme;
    } else {
        return DEFAULT_THEME.profileName;
    }
}
