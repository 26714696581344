import {
    Avatar,
    Body2,
    Button,
    Divider,
    makeStyles,
    Menu,
    MenuDivider,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
    mergeClasses,
    tokens,
} from '@fluentui/react-components';
import { PersonFeedbackRegular, PersonFilled, QuestionCircleRegular, SettingsRegular } from '@fluentui/react-icons';
import React, { useContext } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { SignInContext } from '../../app';
import { getIsSignedIn, getUser, useConfig, UserContext } from '../../data';
import { AccountImage } from '../../icons/status';
import { TABLET_AND_BELOW, useAppStyles } from '../../styles';
import { IntlProps, TrackedButton, TrackedMenuItem } from '../common';
import DeleteAccountDialog from '../identity/delete-account-dialog';
import AboutDialog from './about-dialog';
import DevToolsMenu from './dev-tools-menu';
import ThemeMenu from './theme-menu';

const useStyles = makeStyles({
    profile: {
        margin: tokens.spacingHorizontalS,
    },
    profileImage: {
        width: '4.68rem',
        height: '4.68rem',
        [TABLET_AND_BELOW]: {
            display: 'none',
        },
    },
});

// Header's far end group of controls (mostly related to identity).
export default injectIntl((props: IntlProps) => {
    const { intl } = props;
    const appStyles = useAppStyles();
    const styles = useStyles();
    const { curriculumAuthoring, instructorVerification, reportIssueUrl } = useConfig();
    const navigate = useNavigate();
    const [store, dispatch] = useContext(UserContext);
    const isSignedIn = getIsSignedIn(store);
    const user = getUser(store);
    const { ensureSignedIn } = useContext(SignInContext);
    const [aboutOpen, setAboutOpen] = React.useState(false);
    const [confirmDeleteOpen, setConfirmDeleteOpen] = React.useState(false);

    const signInText = <FormattedMessage description="Header button text" defaultMessage="Sign in" />;

    // Settings menu items.
    const settingsItems = (
        <>
            <ThemeMenu />
            <DevToolsMenu asSubMenu />
        </>
    );

    // Feedback menu items.
    const feedbackItems = (
        <>
            <TrackedMenuItem trackAction="menu-report-a-bug" onClick={() => window.open(reportIssueUrl, '_blank')}>
                <FormattedMessage description="Header menu item text" defaultMessage="Report a bug" />
            </TrackedMenuItem>
            <TrackedMenuItem trackAction="menu-report-abuse" onClick={() => navigate('/report')}>
                <FormattedMessage description="Header menu item text" defaultMessage="Report abuse" />
            </TrackedMenuItem>
        </>
    );

    // Help menu items.
    const helpItems = (
        <>
            <TrackedMenuItem trackAction="menu-faq" onClick={() => navigate('/faq')}>
                <FormattedMessage description="Header menu item text" defaultMessage="Frequently asked questions" />
            </TrackedMenuItem>
            <TrackedMenuItem trackAction="menu-about" onClick={() => setAboutOpen(true)}>
                <FormattedMessage description="Header menu item text" defaultMessage="About" />
            </TrackedMenuItem>
        </>
    );

    // Profile information and menu items for signed-in user.
    const signedInItems = (
        <>
            <div className={mergeClasses(appStyles.flexRowCentered, styles.profile)}>
                <AccountImage className={styles.profileImage} />
                <div>
                    <Body2 className={appStyles.alignCenter}>{user?.displayName}</Body2>
                    <div className={appStyles.textSmall}>{user?.providerId}</div>
                </div>
            </div>
            <Divider />
            {curriculumAuthoring && (
                <TrackedMenuItem trackAction="menu-link-github">
                    <FormattedMessage description="Header menu item text" defaultMessage="Link GitHub account" />
                </TrackedMenuItem>
            )}
            <Menu>
                <MenuTrigger>
                    <MenuItem>
                        <FormattedMessage description="Header menu item text" defaultMessage="Account management" />
                    </MenuItem>
                </MenuTrigger>
                <MenuPopover>
                    <MenuList>
                        <TrackedMenuItem
                            trackAction="menu-download-data"
                            onClick={() => dispatch({ type: 'DownloadData' })}
                        >
                            <FormattedMessage description="Header menu item text" defaultMessage="Download data" />
                        </TrackedMenuItem>
                        <TrackedMenuItem trackAction="menu-delete-account" onClick={() => setConfirmDeleteOpen(true)}>
                            <FormattedMessage description="Header menu item text" defaultMessage="Delete account" />
                        </TrackedMenuItem>
                    </MenuList>
                </MenuPopover>
            </Menu>
            {instructorVerification && (
                <TrackedMenuItem trackAction="menu-instructor-verification">
                    <FormattedMessage
                        description="Header menu item text"
                        defaultMessage="Manage instructor verification"
                    />
                </TrackedMenuItem>
            )}
            <Divider />
            <TrackedMenuItem trackAction="menu-sign-out" onClick={() => dispatch({ type: 'SignOut' })}>
                <FormattedMessage description="Header menu item text" defaultMessage="Sign out" />
            </TrackedMenuItem>
        </>
    );

    const title = isSignedIn
        ? intl.formatMessage(
              { description: 'User avatar title', defaultMessage: 'Signed in as {name}' },
              { name: user?.displayName }
          )
        : intl.formatMessage({
              description: 'Sign-in menu title',
              defaultMessage: 'Sign in to access full functionality.',
          });

    return (
        <>
            {/* Display regular UI in desktop layouts. */}
            <div className={mergeClasses(appStyles.desktopOnly, appStyles.flexRowSmallGap)}>
                <Menu>
                    <MenuTrigger>
                        <Button appearance="subtle" icon={<SettingsRegular />} title="Settings" />
                    </MenuTrigger>
                    <MenuPopover>
                        <MenuList>{settingsItems}</MenuList>
                    </MenuPopover>
                </Menu>
                <Menu>
                    <MenuTrigger>
                        <Button appearance="subtle" icon={<PersonFeedbackRegular />} title="Provide feedback" />
                    </MenuTrigger>
                    <MenuPopover>
                        <MenuList>{feedbackItems}</MenuList>
                    </MenuPopover>
                </Menu>
                <Menu>
                    <MenuTrigger>
                        <Button appearance="subtle" icon={<QuestionCircleRegular />} title="Help and support" />
                    </MenuTrigger>
                    <MenuPopover>
                        <MenuList>{helpItems}</MenuList>
                    </MenuPopover>
                </Menu>
                {isSignedIn && user && (
                    <Menu positioning="below-end">
                        <MenuTrigger>
                            <span>
                                <Avatar className={appStyles.cursorPointer} name={user.displayName} title={title} />
                            </span>
                        </MenuTrigger>
                        <MenuPopover>
                            <MenuList>{signedInItems}</MenuList>
                        </MenuPopover>
                    </Menu>
                )}
                {!isSignedIn && (
                    <TrackedButton
                        trackAction="sign-in"
                        className={mergeClasses(appStyles.button, appStyles.buttonPrimary, appStyles.buttonMedium)}
                        onClick={() => ensureSignedIn('/my-work')}
                    >
                        {signInText}
                    </TrackedButton>
                )}
            </div>
            {/* Collapse all controls into a corner menu on table and below. */}
            <div className={appStyles.desktopHidden}>
                <Menu>
                    <MenuTrigger>
                        <Button appearance="subtle" icon={<PersonFilled />} title={title} />
                    </MenuTrigger>
                    <MenuPopover>
                        <MenuList>
                            {!isSignedIn && (
                                <TrackedMenuItem trackAction="menu-sign-in" onClick={() => ensureSignedIn('/my-work')}>
                                    {signInText}
                                </TrackedMenuItem>
                            )}
                            {isSignedIn && signedInItems}
                            <MenuDivider />
                            {settingsItems}
                            <MenuDivider />
                            {feedbackItems}
                            <MenuDivider />
                            {helpItems}
                        </MenuList>
                    </MenuPopover>
                </Menu>
            </div>
            <AboutDialog open={aboutOpen} onOpenChange={(_, { open }) => setAboutOpen(open)} />
            <DeleteAccountDialog
                open={confirmDeleteOpen}
                onOpenChange={(_, { open }) => setConfirmDeleteOpen(open)}
                onDownload={() => dispatch({ type: 'DownloadData' })}
                onDelete={() => dispatch({ type: 'DeleteAccount' })}
            />
        </>
    );
});
