import { Link, mergeClasses } from '@fluentui/react-components';
import { DifficultyLevel } from '@microsoft/vscodeedu-api';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CourseType, ProjectType } from '../../models';
import { useAppStyles } from '../../styles';
import ProjectTypeLabel from '../common/project-type-label';
import CourseTypeLabel from './course-type-label';
import DifficultyLabel from './difficulty-label';
import RequiredTimeLabel from './required-time-label';

// Course card properties.
export type CourseCardProps = Readonly<{
    className?: string;
    cardImage: string;
    codeType: ProjectType;
    type: CourseType;
    level: DifficultyLevel;
    title: string;
    duration: number;
    description?: string;
    tags?: ReadonlyArray<string>;
    onClick: () => void;
}>;

// Course card properties.
export default (props: CourseCardProps) => {
    const { className, cardImage, codeType, type, level, title, duration, description, tags, onClick } = props;
    const styles = useAppStyles();

    return (
        <div className={mergeClasses(className, styles.card)}>
            <div className={styles.cardMedia}>
                <div className={styles.cardMediaImage} style={{ backgroundImage: `url('${cardImage}')` }} />
            </div>
            <div className={styles.cardContent}>
                <ProjectTypeLabel className={styles.cardCategory} type={codeType} />
                <Link className={mergeClasses(styles.cardTitle, styles.linkButton)} onClick={onClick}>
                    {title}
                </Link>
                {description !== undefined && <div className={styles.cardDescription}>{description}</div>}
                {tags && (
                    <div className={styles.cardDescription}>
                        <span className={styles.textBold}>
                            <FormattedMessage
                                description="Text before list of course topics."
                                defaultMessage="Topics covered:"
                            />
                        </span>
                        &nbsp;
                        {tags.join(', ')}
                    </div>
                )}
                <div className={styles.cardFooter}>
                    <CourseTypeLabel type={type} />
                    •
                    <DifficultyLabel level={level} />
                    {!!duration && (
                        <>
                            •
                            <RequiredTimeLabel minutes={duration} />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};
