import React, { HTMLProps } from 'react';
import { FormattedMessage } from 'react-intl';
import { CourseType } from '../../models';

// Course type label properties.
export type CourseTypeLabelProps = Readonly<
    HTMLProps<HTMLDivElement> & {
        type: CourseType;
    }
>;

// Course type label.
export default (props: CourseTypeLabelProps) => {
    const { type } = props;
    switch (type) {
        case 'guided-tutorial':
            return (
                <div {...props}>
                    <FormattedMessage description="Guided tutorial course type" defaultMessage="Guided Tutorial" />
                </div>
            );
        default:
            return (
                <div {...props}>
                    <FormattedMessage description="Curriculum course type" defaultMessage="Course" />
                </div>
            );
    }
};
