import {
    Body1,
    Body1Strong,
    Caption1,
    Card,
    makeStaticStyles,
    makeStyles,
    mergeClasses,
    shorthands,
    Skeleton,
    SkeletonItem,
    Title1,
    Title3,
    tokens,
} from '@fluentui/react-components';
import { Clock16Regular } from '@fluentui/react-icons';
import * as React from 'react';
import { PropsWithChildren, useContext, useMemo } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { SignInContext } from '../app';
import AuthorInfo from '../components/author-info';
import { IntlProps, TrackContext, TrackedButton } from '../components/common';
import DifficultyIcon from '../components/common/difficulty-icon';
import EducatorResources from '../components/course-card/educator-resources';
import RequiredTimeLabel from '../components/course-card/required-time-label';
import PageContainer from '../components/page-container';
import TableOfContents from '../components/table-of-contents/table-of-contents';
import { CourseContext, getCourse, getIsCourseStarted, useOpenCourseUrl, useUserProgress } from '../data';
import { MOBILE_AND_BELOW, TABLET_AND_BELOW, useAppStyles } from '../styles';
import { useRenderer } from '../utilities/markdown';

const useStyles = makeStyles({
    container: {
        minHeight: '100vh',
    },
    heroBackdrop: {
        backgroundSize: 'cover',
        backgroundPositionX: 'center',
        backgroundPositionY: 'center',
    },
    heroContainer: {
        backdropFilter: 'blur(10px)',
        ...shorthands.padding('2rem'),
        ...shorthands.gap('1.5rem'),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        [MOBILE_AND_BELOW]: {
            flexDirection: 'column-reverse',
        },
    },
    heroContent: {
        maxWidth: '600px',
        display: 'flex',
        flexDirection: 'column',
        [MOBILE_AND_BELOW]: {
            maxWidth: 'unset',
            maxHeight: '14rem',
        },
    },
    heroCaptionText: {
        color: tokens.colorNeutralForeground2,
        mixBlendMode: 'difference',
        paddingBottom: '0.5rem',
    },
    heroDescriptionText: {
        mixBlendMode: 'difference',
        [MOBILE_AND_BELOW]: {
            flexShrink: 1,
            display: '-webkit-box',
            '-webkit-box-orient': 'vertical',
            '-webkit-line-clamp': 3,
            overflowY: 'hidden',
        },
    },
    heroButton: {
        marginTop: '1rem',
    },
    heroImage: {
        width: '370px',
        height: '220px',
        backgroundSize: 'cover',
        backgroundPositionX: 'center',
        backgroundPositionY: 'center',
        ...shorthands.borderRadius(tokens.borderRadiusLarge),
        [MOBILE_AND_BELOW]: {
            width: 'unset',
            aspectRatio: '360 / 150',
        },
    },
    content: {
        display: 'flex',
        flexDirection: 'row',
        paddingBottom: '1rem',
        [MOBILE_AND_BELOW]: {
            flexDirection: 'column-reverse',
        },
    },
    contentLeft: {
        flexGrow: 1,
        paddingLeft: '2rem',
        paddingRight: '2rem',
        [TABLET_AND_BELOW]: {
            paddingLeft: '1rem',
            paddingRight: '1rem',
        },
    },
    contentRight: {
        minWidth: '20rem',
        paddingRight: '2rem',
        [TABLET_AND_BELOW]: {
            minWidth: '15rem',
            paddingRight: '1rem',
        },
        [MOBILE_AND_BELOW]: {
            paddingLeft: '1rem',
        },
    },
    detailsStat: {
        display: 'flex',
        flexDirection: 'row',
        textTransform: 'capitalize',
    },
    detailsStatIcon: {
        marginLeft: '0.5rem',
        transform: 'translateY(2px)',
        width: tokens.spacingHorizontalL,
    },
    aboutTheAuthorHeader: {
        marginBottom: '1rem',
    },
});

const useMarkdownStyles = makeStaticStyles({
    '.objectives-markdown ul': {
        listStyleImage: `url("data:image/svg+xml,%3Csvg width='18' height='18' fill='none' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m8.5 16.586-3.793-3.793a1 1 0 0 0-1.414 1.414l4.5 4.5a1 1 0 0 0 1.414 0l11-11a1 1 0 0 0-1.414-1.414L8.5 16.586Z' fill='%234DA64D'/%3E%3C/svg%3E")`,
    },
});

// Course information page.
export default injectIntl((props: IntlProps) => {
    const { intl } = props;
    const styles = useStyles();
    const appStyles = useAppStyles();
    useMarkdownStyles();

    const params = useParams();
    const courseId = params['courseId']!;
    const [courseStore] = useContext(CourseContext);
    const course = getCourse(courseStore, courseId);
    const [progressStore] = useUserProgress();
    const started = getIsCourseStarted(progressStore, courseId);
    const openCourseUrl = useOpenCourseUrl(courseId)?.toString() ?? '';

    const objectivesMarkdown = useRenderer(course?.objectives);

    const learnTitle = intl.formatMessage({
        description: 'Course info page learning objective section title',
        defaultMessage: 'What will you learn?',
    });
    const tocTitle = intl.formatMessage({
        description: 'Course info page table of contents section title',
        defaultMessage: 'Table of contents',
    });
    const detailsTitle = intl.formatMessage({
        description: 'Course info page course details section title',
        defaultMessage: 'Course details',
    });
    const educatorResourcesTitle = intl.formatMessage({
        description: 'Course info page educator resources section title',
        defaultMessage: 'Educator resources',
    });

    const buttonText = useMemo(
        () =>
            started
                ? intl.formatMessage({
                      description: 'Course info page resume course button',
                      defaultMessage: 'Resume course',
                  })
                : intl.formatMessage({
                      description: 'Course info page start course button',
                      defaultMessage: 'Start course',
                  }),
        [started, intl]
    );

    if (!course) {
        return (
            <Skeleton>
                <div className={styles.container}>
                    <div className={mergeClasses(styles.heroContainer, 'content-width')} />
                    <div className={mergeClasses(styles.content, 'content-width')}>
                        <div className={styles.contentLeft}>
                            <CourseInfoSection title={learnTitle}>
                                <MultiSkeleton num={5} />
                            </CourseInfoSection>
                            <CourseInfoSection title={tocTitle}>
                                <MultiSkeleton num={10} />
                            </CourseInfoSection>
                        </div>
                        <div className={styles.contentRight}>
                            <CourseInfoSection title={detailsTitle}>
                                <MultiSkeleton num={7} />
                            </CourseInfoSection>
                        </div>
                    </div>
                </div>
            </Skeleton>
        );
    }

    return (
        <TrackContext.Provider value={{ container: 'course-info' }}>
            <PageContainer className={styles.container}>
                <div className="content-width">
                    <div
                        className={styles.heroBackdrop}
                        style={{
                            backgroundImage: `linear-gradient(to right, ${tokens.colorNeutralBackground1}, rgb(0 0 0/ 50%)), url('${course.cardImage}')`,
                        }}
                    >
                        <div className={styles.heroContainer}>
                            <div className={styles.heroContent}>
                                <Title1>{course.title}</Title1>
                                <div className={styles.heroCaptionText}>
                                    <Caption1>
                                        <FormattedMessage
                                            description="Course info page author credit"
                                            defaultMessage="By {AUTHOR}"
                                            values={{
                                                AUTHOR: course.authors?.map((o) => o.displayName).join(', '),
                                            }}
                                        />
                                    </Caption1>
                                </div>
                                <div className={styles.heroDescriptionText}>
                                    <Body1>{course.description}</Body1>
                                </div>
                                <div>
                                    <SignInContext.Consumer>
                                        {({ ensureSignedIn }) => (
                                            <TrackedButton
                                                trackAction="open"
                                                role="link"
                                                className={mergeClasses(
                                                    styles.heroButton,
                                                    appStyles.buttonPrimary,
                                                    appStyles.buttonMedium
                                                )}
                                                onClick={() => ensureSignedIn(openCourseUrl)}
                                            >
                                                {buttonText}
                                            </TrackedButton>
                                        )}
                                    </SignInContext.Consumer>
                                </div>
                            </div>
                            <div
                                className={styles.heroImage}
                                style={{ backgroundImage: `url('${course.cardImage}')` }}
                            />
                        </div>
                    </div>
                    <div className={styles.content}>
                        <div className={styles.contentLeft}>
                            <CourseInfoSection title={learnTitle}>
                                <div className="objectives-markdown">{objectivesMarkdown}</div>
                            </CourseInfoSection>
                            <CourseInfoSection title={tocTitle}>
                                <TableOfContents units={course.units} openCourseUrl={openCourseUrl} />
                            </CourseInfoSection>
                        </div>
                        <div className={styles.contentRight}>
                            <CourseInfoSection title={detailsTitle}>
                                <div className={styles.detailsStat}>
                                    <div className={appStyles.flexGrow1}>
                                        <Body1Strong>
                                            <FormattedMessage
                                                description="Course details level stat label"
                                                defaultMessage="Level"
                                            />
                                        </Body1Strong>
                                    </div>
                                    <div>
                                        {course.level}
                                        <DifficultyIcon className={styles.detailsStatIcon} level={course.level} />
                                    </div>
                                </div>
                                {!!course.duration && (
                                    <div className={styles.detailsStat}>
                                        <div className={appStyles.flexGrow1}>
                                            <Body1Strong>
                                                <FormattedMessage
                                                    description="Course details duration stat label"
                                                    defaultMessage="Duration"
                                                />
                                            </Body1Strong>
                                        </div>
                                        <div
                                            className={mergeClasses(
                                                appStyles.flexRowSmallGap,
                                                appStyles.flexRowCentered
                                            )}
                                        >
                                            <RequiredTimeLabel minutes={course.duration} />
                                            <Clock16Regular />
                                        </div>
                                    </div>
                                )}
                                <div>
                                    <div className={styles.aboutTheAuthorHeader}>
                                        <Body1Strong>
                                            <FormattedMessage
                                                description="Course details about the author section label"
                                                defaultMessage="About the author"
                                            />
                                        </Body1Strong>
                                    </div>
                                    <div className={appStyles.flexColumnSmallGap}>
                                        {course.authors?.map((o) => <AuthorInfo key={o.displayName} {...o} />)}
                                    </div>
                                </div>
                            </CourseInfoSection>
                            {course.educatorResources && (
                                <CourseInfoSection title={educatorResourcesTitle}>
                                    <EducatorResources educatorResources={course.educatorResources} />
                                </CourseInfoSection>
                            )}
                        </div>
                    </div>
                </div>
            </PageContainer>
        </TrackContext.Provider>
    );
});

const useCourseSectionStyles = makeStyles({
    container: {
        marginTop: '1rem',
    },
    title: {
        marginBottom: '0.5rem',
    },
    card: {
        ...shorthands.padding('1rem'),
        ...shorthands.border('1px', 'solid', tokens.colorNeutralBackground3),
        backgroundColor: tokens.colorNeutralBackground2,
    },
});

// Course information section properties.
type CourseSectionProps = PropsWithChildren<{
    readonly title: string;
}>;

// Course information section.
const CourseInfoSection = (props: CourseSectionProps) => {
    const { title, children } = props;
    const styles = useCourseSectionStyles();

    return (
        <div className={styles.container}>
            <div className={styles.title}>
                <Title3>{title}</Title3>
            </div>
            <Card className={styles.card}>{children}</Card>
        </div>
    );
};

const MultiSkeleton = (props: { num: number }) => {
    const { num } = props;

    const skeletons: React.ReactElement[] = [];
    for (let i = 0; i < num; i++) {
        skeletons.push(<SkeletonItem size={12} key={i} style={{ width: Math.random() * 100 + '%' }} />);
    }

    return <>{skeletons}</>;
};
