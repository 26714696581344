import { Radio, RadioProps, makeStyles, mergeClasses, shorthands, tokens } from '@fluentui/react-components';
import React, { useMemo } from 'react';

const useStyles = makeStyles({
    root: {
        '> label': {
            ...shorthands.padding(0),
        },
        '> input': {
            width: '100%',
        },
        '&:hover': {
            backgroundColor: tokens.colorNeutralBackground1Hover,
        },
        '&:active': {
            backgroundColor: tokens.colorNeutralBackground1Pressed,
        },
    },
    indicator: {
        display: 'none',
    },
    content: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '14rem',
        height: '4.3rem',
        borderRadius: tokens.borderRadiusLarge,
        ...shorthands.border(tokens.strokeWidthThin, 'solid', tokens.colorNeutralStroke2),
    },
    selected: {
        backgroundColor: tokens.colorNeutralBackground1Selected,
    },
    icon: {
        minWidth: tokens.spacingHorizontalXXXL,
        minHeight: tokens.spacingHorizontalXXXL,
        ...shorthands.margin(tokens.spacingHorizontalM),
    },
    title: {
        fontSize: tokens.fontSizeBase300,
        lineHeight: tokens.lineHeightBase300,
        color: tokens.colorNeutralForeground1,
    },
    description: {
        fontSize: tokens.fontSizeBase200,
        lineHeight: tokens.lineHeightBase200,
        color: tokens.colorNeutralStroke1,
    },
});

// Radio card option properties.
export type RadioCardProps = Readonly<RadioProps> & {
    readonly icon?: JSX.Element;
    readonly title: string;
    readonly description?: string;
    readonly selected: boolean;
};

// Radio option that renders as a card with icon and title/description.
export default (props: RadioCardProps) => {
    const { icon, title, description, selected } = props;
    const styles = useStyles();
    const contentStyles = useMemo(() => mergeClasses(styles.content, selected && styles.selected), [styles, selected]);
    return (
        <Radio
            {...props}
            className={styles.root}
            indicator={{ className: styles.indicator }}
            aria-label={title}
            label={
                <div className={contentStyles}>
                    <div className={styles.icon}>{icon}</div>
                    <div>
                        <div className={styles.title}>{title}</div>
                        {description !== undefined && <div className={styles.description}>{description}</div>}
                    </div>
                </div>
            }
        />
    );
};
