import { shorthands, tokens } from '@fluentui/react-components';
import { defineStyles } from './define-styles';

// Button styles.
export const buttonStyles = defineStyles({
    // Base button style.
    button: {
        fontSize: '15px',
        lineHeight: '22px',
        whiteSpace: 'nowrap',
        borderRadius: tokens.borderRadiusXLarge,
        ...shorthands.padding(tokens.spacingVerticalM, tokens.spacingHorizontalXXL),
        ...shorthands.outline(tokens.strokeWidthThick, 'solid', 'transparent'),
        '&:focus-visible': {
            borderRadius: tokens.borderRadiusXLarge,
        },
    },
    // Button with primary appearance.
    buttonPrimary: {
        '&:not(:disabled)': {
            ...shorthands.border(tokens.strokeWidthThick, 'solid', tokens.colorBrandBackground),
            backgroundColor: tokens.colorBrandBackground,
            color: tokens.colorNeutralForegroundOnBrand,
        },
        '&:hover:not(:disabled)': {
            ...shorthands.border(tokens.strokeWidthThick, 'solid', tokens.colorBrandBackgroundHover),
            backgroundColor: tokens.colorBrandForegroundOnLightHover,
        },
        '&:active:not(:disabled)': {
            backgroundColor: tokens.colorBrandForegroundOnLightPressed,
            color: tokens.colorNeutralForegroundOnBrand,
        },
        '&:disabled': {
            ...shorthands.border(tokens.strokeWidthThick, 'solid', 'transparent'),
        },
    },
    // Button for dangerous operations.
    buttonDanger: {
        backgroundColor: `${tokens.colorStatusDangerBackground2} !important`,
        ...shorthands.border(tokens.strokeWidthThick, 'solid', `${tokens.colorStatusDangerBackground2} !important`),
        '&:hover:not(:disabled)': {
            backgroundColor: `${tokens.colorStatusDangerBackground3} !important`,
            ...shorthands.border(tokens.strokeWidthThick, 'solid', `${tokens.colorStatusDangerBackground3} !important`),
        },
        '&:active': {
            backgroundColor: `${tokens.colorStatusDangerBackground1} !important`,
            ...shorthands.border(tokens.strokeWidthThick, 'solid', `${tokens.colorStatusDangerBackground1} !important`),
        },
        '&:disabled': {
            backgroundColor: `${tokens.colorStatusDangerBackground1} !important`,
            ...shorthands.border(tokens.strokeWidthThick, 'solid', `${tokens.colorStatusDangerBackground1} !important`),
        },
    },
    // Button with reduced padding.
    buttonMedium: {
        minWidth: 'unset',
        ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalL),
    },
    // Button with minimal padding.
    buttonSmall: {
        minWidth: 'unset',
        ...shorthands.padding(tokens.spacingVerticalXS, tokens.spacingHorizontalM),
    },
    // A hyper-link button.
    linkButton: {
        textDecorationLine: 'none',
        '&:hover': {
            textDecorationLine: 'underline',
        },
        '&:focus-visible': {
            textDecorationLine: 'underline',
            textDecorationStyle: 'double',
            outlineStyle: 'none',
        },
    },
    // A tab/navigation pill button.
    pillButton: {
        ...shorthands.padding(0),
        '& >label': {
            color: '#dceef8 !important',
            backgroundColor: '#54a5e226',
            fontSize: tokens.fontSizeBase400,
            fontWeight: tokens.fontWeightSemibold,
            borderRadius: '12.5rem',
            ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalXXL),
            ...shorthands.border('none'),
            '&:not(:active)': {
                ...shorthands.transition('all', tokens.durationNormal),
            },
            '&:hover': {
                backgroundColor: '#54a5e266',
            },
            '&:active': {
                color: '#f4fafd',
                backgroundColor: '#006dc1 !important',
            },
            '&:focus': {
                color: '#f4fafd',
                backgroundColor: '#006dc1 !important',
            },
        },
        '& input:checked~label': {
            color: '#0e1726 !important',
            backgroundColor: '#b0d5f2 !important',
        },
        '&>div': {
            display: 'none',
        },
    },
});
