import {
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogProps,
    DialogSurface,
    DialogTitle,
    Link,
} from '@fluentui/react-components';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { WarningImage } from '../../icons/status';
import { useAppStyles } from '../../styles';
import { TrackContext } from '../common';
import { CancelButton, ConfirmButton, ConfirmationField } from '../dialogs';
import CloseButton from '../dialogs/close-button';

// Delete project confirmation dialog properties.
export type DeleteProjectDialogProps = Readonly<
    Partial<DialogProps> & {
        projectTitle: string;
        onDownload: () => void;
        onDelete: () => void;
    }
>;

// Delete project confirmation dialog.
export default (props: DeleteProjectDialogProps) => {
    const { projectTitle, onDownload, onDelete, open } = props;
    const styles = useAppStyles();
    const [confirmed, setConfirmed] = useState(false);

    // Reset confirmation input text value on reopening the dialog.
    const [value, setValue] = useState('');
    useEffect(() => setValue(''), [open]);

    return (
        <TrackContext.Provider value={{ container: 'delete-project-dialog' }}>
            <Dialog {...props}>
                <DialogSurface>
                    <DialogBody>
                        <DialogTitle action={<CloseButton />}>
                            <FormattedMessage
                                description="Delete project dialog title."
                                defaultMessage="Delete project"
                            />
                        </DialogTitle>
                        <DialogContent className={styles.flexRowCentered}>
                            <WarningImage className={styles.dialogImage} />
                            <div className={styles.dialogContent}>
                                <div>
                                    <FormattedMessage
                                        description="Confirmation text on delete project dialog."
                                        defaultMessage="Are you sure you want to delete project ''{PROJECT_TITLE}''?"
                                        values={{ PROJECT_TITLE: projectTitle }}
                                    />
                                </div>
                                <div>
                                    <FormattedMessage
                                        description="Title for a list of items that will be lost when project is deleted."
                                        defaultMessage="You will permanently lose:"
                                    />
                                    <ul>
                                        <li>
                                            <FormattedMessage
                                                description="An item in the list of things that will be lost when deleting a project."
                                                defaultMessage="All code and other files within the project"
                                            />
                                        </li>
                                        <li>
                                            <FormattedMessage
                                                description="An item in the list of things that will be lost when deleting a project."
                                                defaultMessage="Public web address of the project (if published)"
                                            />
                                        </li>
                                    </ul>
                                    <div className={styles.textSemibold}>
                                        <FormattedMessage
                                            description="Bolded warning text on the delete project dialog."
                                            defaultMessage="Once deleted, your project cannot be recovered!"
                                        />
                                    </div>
                                    <br />
                                    <div>
                                        <FormattedMessage
                                            description="Message on the delete project dialog. DOWNLOAD_LINK is a link to download the project code with text 'Click here'."
                                            defaultMessage="{DOWNLOAD_LINK} to download project's code before it is deleted."
                                            values={{
                                                DOWNLOAD_LINK: (
                                                    <Link onClick={onDownload}>
                                                        <FormattedMessage
                                                            description="Link text on the delete project dialog to download project code."
                                                            defaultMessage="Click here"
                                                        />
                                                    </Link>
                                                ),
                                            }}
                                        />
                                    </div>
                                    <br />
                                    <ConfirmationField
                                        initialValue={value}
                                        onChange={setConfirmed}
                                        confirmationText="DELETE"
                                    />
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <CancelButton />
                            <ConfirmButton className={styles.buttonDanger} onClick={onDelete} disabled={!confirmed}>
                                <FormattedMessage
                                    description="Delete project dialog confirmation button text."
                                    defaultMessage="Delete project"
                                />
                            </ConfirmButton>
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        </TrackContext.Provider>
    );
};
