import {
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogProps,
    DialogSurface,
    DialogTitle,
} from '@fluentui/react-components';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { WarningImage } from '../../icons/status';
import { useAppStyles } from '../../styles';
import { TrackContext } from '../common';
import { CancelButton, ConfirmButton, ConfirmationField } from '../dialogs';
import CloseButton from '../dialogs/close-button';

// Unpublish project confirmation dialog properties.
export type UnpublishProjectDialogProps = Readonly<
    Partial<DialogProps> & {
        title: string;
        onUnpublish: () => void;
    }
>;

// Unpublish project confirmation dialog.
export default (props: UnpublishProjectDialogProps) => {
    const { title, onUnpublish, open } = props;
    const styles = useAppStyles();
    const [confirmed, setConfirmed] = useState(false);

    // Reset confirmation input text value on reopening the dialog.
    const [value, setValue] = useState('');
    useEffect(() => setValue(''), [open]);

    return (
        <TrackContext.Provider value={{ container: 'unpublish-project-dialog' }}>
            <Dialog {...props}>
                <DialogSurface>
                    <DialogBody>
                        <DialogTitle action={<CloseButton />}>
                            <FormattedMessage
                                description="Unpublish project dialog title."
                                defaultMessage="Unpublish project"
                            />
                        </DialogTitle>
                        <DialogContent className={styles.flexRowCentered}>
                            <WarningImage className={styles.dialogImage} />
                            <div className={styles.dialogContent}>
                                <div>
                                    <FormattedMessage
                                        description="Confirmation text on unpublish project dialog."
                                        defaultMessage="Are you sure you want to unpublish project ''{PROJECT_TITLE}''?"
                                        values={{ PROJECT_TITLE: title }}
                                    />
                                </div>
                                <div>
                                    <FormattedMessage
                                        description="Warning message on the unpublish project dialog."
                                        defaultMessage="Your project's web address will expire and become unavailable. Another web project may be assigned this web address in the future."
                                    />
                                </div>
                                <ConfirmationField
                                    initialValue={value}
                                    onChange={setConfirmed}
                                    confirmationText="UNPUBLISH"
                                />
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <CancelButton />
                            <ConfirmButton className={styles.buttonDanger} onClick={onUnpublish} disabled={!confirmed}>
                                <FormattedMessage
                                    description="Unpublish project dialog confirmation button text."
                                    defaultMessage="Unpublish project"
                                />
                            </ConfirmButton>
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        </TrackContext.Provider>
    );
};
