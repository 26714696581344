import { TableCellLayout, makeStyles, tokens } from '@fluentui/react-components';
import React from 'react';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: tokens.spacingVerticalS,
        minHeight: '4.8rem',
        paddingTop: tokens.spacingVerticalS,
        paddingBottom: tokens.spacingVerticalS,
    },
    description: {
        paddingTop: tokens.spacingVerticalXS,
        maxHeight: '2rem',
        color: tokens.colorNeutralForeground4,
        fontSize: tokens.fontSizeBase200,
        lineHeight: tokens.lineHeightBase200,
        overflowX: 'hidden',
        overflowY: 'hidden',
    },
});

// Curriculum name cell properties.
export type NameCellProps = Readonly<{
    title: string;
    description?: string;
}>;

// Curriculum name data grid cell layout.
export default (props: NameCellProps) => {
    const { title, description } = props;
    const styles = useStyles();

    return (
        <TableCellLayout className={styles.root}>
            <div>{title}</div>
            <div className={styles.description}>{description}</div>
        </TableCellLayout>
    );
};
