import { TableCellLayout } from '@fluentui/react-components';
import React, { useMemo } from 'react';
import { injectIntl } from 'react-intl';
import { timeSince } from '../../utilities/time';
import { IntlProps } from '../common';

// Last published cell properties.
export type LastPublishedCellProps = IntlProps<{
    lastPublished?: Date;
}>;

// Last Published data grid cell contents.
export default injectIntl((props: LastPublishedCellProps) => {
    const { intl, lastPublished } = props;

    const lastPublishedText = useMemo(
        () => (lastPublished !== undefined ? timeSince(lastPublished.getTime(), intl) : ''),
        [lastPublished, intl]
    );

    return <TableCellLayout>{lastPublishedText}</TableCellLayout>;
});
